import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { CiSearch } from "react-icons/ci";
import { createSearchParams, useNavigate } from 'react-router-dom';

import red_stripe from "../../../images/red-stripe.png";
import yellow_stripe from "../../../images/yellow-stripe.png";
import black_stripe from "../../../images/black-stripe.png";

import "./SearchBody.styles.css";

const SearchBody = () => {
    // Text that gets animated
    const subtitle = "Find a tutor for your course at University of Maryland".split(" ");
    const instructions = "Enter a tutor, course, or professor".split(" ");

    // Typing effect that occurs in the search bar
    const [examples] = useTypewriter({
        words: ["                      ","CMSC420", "Justin Wyss-Gallifent", "BGMT110", "Jeffrey Mund", "Wadood", "CMSC335", "Nelson Padua-Pérez", "MATH410", "Terence Long"],
        loop: {},
        cursor: true,
        cursorStyle: "|",
        typeSpeed: 70,
        deleteSpeed: 50,
        delaySpeed: 2000,
    });

    // Used to redirect with search query
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    }

    // Handles search query if enter key is pressed
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    }

    // Handles search operation
    const handleSearchSubmit = async () => {
        // Redirect to MarketBody with query
        navigate({
            pathname: "/market",
            search: createSearchParams({
                search: searchQuery
            }).toString(),
        });
    };

    return (
        <div>
            <div className="searchbody-main">
                <motion.div
                    className="searchbody-header"
                >
                    <h1 className="searchbody-title">
                        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.75 }}>Welcome </motion.span>
                        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.75 }}>to </motion.span>

                        <motion.span className="searchbody-terp" initial={{ opacity: 0, y: 50 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: .9, duration: 0.7 }}>Terp</motion.span>
                        <motion.span className="searchbody-net" initial={{ opacity: 0, y: -50 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: .9, duration: 0.7 }}>Net</motion.span>
                    </h1>

                    <motion.h6
                        className="searchbody-subtitle"
                        transition={{ delay: 2 }}
                    >
                        {subtitle.map((character, idx) => (
                            <motion.span
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 1.5 + idx / 10 }}
                            >
                                {character + " "}
                            </motion.span>
                        ))}
                    </motion.h6>
                </motion.div>

                <div>
                    <div className="searchbody-searchbar">
                        <motion.input
                            id="searchbody-bar"
                            type="text"
                            onChange={handleSearchQuery}
                            onKeyPress={handleKeyPress}
                            placeholder={examples}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        />
                        <button onClick={handleSearchSubmit} className="searchbody-search-submit">
                            <CiSearch className="searchbody-search-icon" size={30} />
                        </button>
                    </div>
                </div>

                <motion.h6
                    className="searchbody-instructions"
                >
                    {instructions.map((character, idx) => (
                        <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 3 + idx / 10 }}
                        >
                            {character + " "}
                        </motion.span>
                    ))}
                </motion.h6>
            </div>
            <div className="searchbody-overlay">
                <motion.img
                    className="searchbody-yellow-img"
                    src={yellow_stripe}
                    initial={{ x: -1000, opacity: 0, }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: .9, delay: 0.5 }}
                />
                <motion.img
                    className="searchbody-red-img"
                    src={red_stripe}
                    initial={{ x: -1000, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: .9, delay: .6 }}
                />
                <motion.img
                    className="searchbody-black-img"
                    src={black_stripe}
                    initial={{ x: -1000, opacity: 0, y: -10 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: .8 }}
                />
            </div>
        </div>
    )
}

export default SearchBody;