import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Rating, Button } from "@mui/material";

import "./TutorPersonalPage.styles.css";

import Header from "../FrontPage/Header/Header";
import Footer from "../FrontPage/Footer/Footer";

const TutorPersonalPage = () => {
    const navigate = useNavigate();

    // Get tutor ID
    const url = window.location.href;
    const parts = url.split('/'); // Split the URL by '/'
    const tutor_id = parts[parts.length - 1]; // Get the last part of the URL    

    // Load data based on unique id from database
    const [tutorData, setTutorData] = useState({});
    const [tutorRating, setTutorRating] = useState(0);

    // Book appointment
    const handleBookAppt = async () => {
        navigate(`/book/${tutor_id}`);
    }

    useEffect(() => {
        const getTutorById = async () => {
            const response = await fetch(
                'https://terpnet-8wz1.onrender.com/getTutors',
                {
                    method: 'POST',
                    credentials: 'include'
                }
            );

            const json = await response.json();
            const tutor = json.filter((tutor) => tutor._id === tutor_id);
            if (tutor.length === 0) {
                return;
            } else {
                setTutorData(tutor[0]);
                setTutorRating(tutor[0].rating);
            }
        }
        getTutorById();
        
    }, [])

    return (
        <div className="tutorpersonal-main">
            <Header />
            <div className="tutorpersonal-page">
                <div className="tutorpersonal-tutor-desc">
                    {/* profile pic */}
                    <img className="tutorpersonal-pfp" src={tutorData?.profilepic} />

                    {/* name */}
                    <span style={{ fontSize: '32px', fontFamily: 'Poppins Heavy', marginTop: '5%' }}>
                        {tutorData?.name}
                    </span>

                    {/* rating / reviews */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '2%', marginTop: '5%' }}>
                        <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Rating: </span>
                        <Rating value={tutorRating} precision={0.25} readOnly />
                        <span style={{ fontSize: '14px', fontFamily: 'Poppins Heavy', marginTop: '1%' }}>{tutorData?.rating}</span>
                    </div>

                    {/* hourly rate */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '2%', marginTop: '8%' }}>
                        <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Rate: </span>
                        <span style={{ fontSize: '20px', fontFamily: 'Poppins Heavy' }}>${tutorData?.rate}/hr</span>
                    </div>

                    {/* book an appointment */}
                    <div className="tutorpersonal-contact-buttons">
                        <Button
                            onClick={() => handleBookAppt()}
                            style={
                                {
                                    backgroundColor: "#D70040",
                                    marginTop: '2%',
                                    padding: '3%',
                                    whiteSpace: 'nowrap',
                                    fontSize: '16px',
                                    width: '85%',
                                }
                            }
                            variant="contained"
                        >
                            Book An Appointment
                        </Button>
                    </div>
                </div>

                <div className="tutorpersonal-tutor-info">
                    {/* bio */}
                    <span className="tutorpersonal-heading">Bio</span>
                    <hr style={{ width: '100%' }} />
                    <span className="tutorpersonal-subheading">
                        {tutorData?.bio}
                    </span>

                    {/* classes tutoring */}
                    <span style={{ marginTop: '5%' }} className="tutorpersonal-heading">Classes Tutoring</span>
                    <hr style={{ width: '100%' }} />
                    <div className="tutorpersonal-tag-body">
                        {tutorData.classes === undefined ?
                            <div>

                            </div>
                            :
                            tutorData?.classes.map((course, idx) => (
                                <div title={course.professor} className="tutorpersonal-tag">
                                    {course.course}
                                </div>
                            ))}
                    </div>

                    {/* education */}
                    {/* <span style={{ marginTop: '5%' }} className="tutorpersonal-heading">Education</span>
                    <hr style={{ width: '100%' }} />
                    <span className="tutorpersonal-subheading">
                        <span style={{ fontFamily: 'Poppins Heavy' }}>School: </span> {tutorData?.education[0]}
                        <br />
                        <span style={{ fontFamily: 'Poppins Heavy' }}>Expected Year: </span>{tutorData?.education[1]}
                    </span> */}


                    {/* work experience 
                    <span style={{ marginTop: '5%' }} className="tutorpersonal-heading">Working Experience</span>
                    <hr style={{ width: '100%' }} />
                    {tutorData?.experience.length > 0 ?
                        (tutorData?.experience.map((job, idx) => (
                            <div className="tutorpersonal-experience">
                                <span style={{ fontFamily: 'Poppins Heavy', marginBottom: '0.75%' }}>
                                    Company: <span style={{ fontFamily: 'None' }}> {job.company}</span>
                                </span>

                                <span style={{ fontFamily: 'Poppins Heavy', marginBottom: '0.75%' }}>
                                    Title: <span style={{ fontFamily: 'None' }}> {job.title}</span>
                                </span>

                                <span style={{ fontFamily: 'Poppins Heavy', marginBottom: '0.75%' }}>
                                    Duration: <span style={{ fontFamily: 'None' }}> {job.from} - {job.to}</span>
                                </span>

                                <span style={{ fontFamily: 'Poppins Heavy', marginBottom: '0.75%' }}>Description:
                                    <span style={{ fontFamily: 'None' }}> {job.description}</span>
                                </span>

                                <hr style={{ width: '50%', marginLeft: 0 }} />
                            </div>
                        )))
                        :
                        <span style={{ fontFamily: 'Poppins Heavy' }}>No Work Experience</span>
                    } */}

                    {/* reviews */}
                    <span style={{ marginTop: '2%' }} className="tutorpersonal-heading">Ratings & Reviews</span>
                    <hr style={{ width: '100%' }} />
                    <div className="tutorpersonal-ratings-and-review">

                        <div className="tutorpersonal-row" style={{ width: '90%' }}>
                            <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Reviews: </span>
                            <div className="tutorpersonal-reviews">
                                {tutorData.classes === undefined ?
                                    <div></div>
                                    :
                                    tutorData?.reviews.map((review, idx) => (
                                        <div className="tutorpersonal-review">
                                            <div className="tutorpersonal-row" style={{ fontSize: '24px', fontFamily: 'Poppins Heavy', alignItems: 'center' }}>
                                                {review.topic}
                                                <Rating value={review.rating} precision={0.25} style={{ marginLeft: '5%' }} />
                                                <span style={{ fontSize: '16px', fontFamily: 'Poppins Heavy', marginLeft: '2%', marginTop: '0.5%' }}>{review.rating}</span>
                                            </div>
                                            <span style={{ fontSize: '20px', fontFamily: 'Poppins Light' }}>{review.review}</span>
                                            <span style={{ fontSize: '16px', color: 'grey', fontFamily: 'Poppins Heavy' }}>&#8211; {review.name}</span>
                                            <hr style={{ width: '100%', marginLeft: 0 }} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TutorPersonalPage;
