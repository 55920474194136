import React, { useEffect, useState } from "react";
import "./Header.styles.css";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

import logo from "../../../images/logo.png";

const backendUrl = 'https://terpnet-8wz1.onrender.com';

const Header = () => {
    // Toggles the vertical drop-down menu
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const [user, setUser] = useState(null);

    const getCurrentUser = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/currentUser`,
                {
                    method: 'GET',
                    credentials: 'include'
                }
            );

            if (response.ok) {
                const json = await response.json();
                const currUser = { status: json.userType, id: json._id };
                setUser(currUser);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    return (
        <nav className={toggleDropDown ? "header-main-dropped" : "header-main"}>
            <Link to="/" className="header-link-logo">
                <img className="header-logo" alt="logo" src={logo} />
            </Link>
            <ul className="header-navbar">
                {/* <li className="header-li">
                    <Link to="/about" className="header-a">About</Link>
                </li> */}
                <li className="header-li">
                    <Link to="/students" className="header-a">For Students</Link>
                </li>
                <li className="header-li">
                    <Link to="/tutors" className="header-a">For Tutors</Link>
                </li>
            </ul>
            {user === null ?
                <Link to="/account">
                    <button id="header-login">
                        Log In
                    </button>
                </Link>
                :
                <Link to={user.status === 'tutor' ? `/account/tutor` : `/account/student`}>
                    <button id="header-login">
                        Dashboard
                    </button>
                </Link>
            }

            <div className="header-mini-menu">
                <IoMenu
                    onClick={() => setToggleDropDown(!toggleDropDown)}
                    className={toggleDropDown ? "header-menu-icon-dropped-down" : "header-menu-icon"}
                />
                {toggleDropDown && (
                    <div>
                        <ul className="header-navbar-mini">
                            <br />
                            <li className="header-li-dropped">
                                <Link to="/about" className="header-a-dropped">About</Link>
                                <hr style={{ width: "75%", backgroundColor: "white", border: "none", height: 2, justifyContent: "center" }} />
                            </li>
                            <li className="header-li-dropped">
                                <Link to="/students" className="header-a-dropped">For Students</Link>
                                <hr style={{ width: "75%", backgroundColor: "white", border: "none", height: 2, justifyContent: "center" }} />
                            </li>
                            <li className="header-li-dropped">
                                <Link to="/tutors" className="header-a-dropped">For Tutors</Link>
                                <hr style={{ width: "75%", backgroundColor: "white", border: "none", height: 2, justifyContent: "center" }} />
                            </li>
                            <li className="header-li-dropped">
                                {user === null ?
                                    <Link to="/account" className="header-a-dropped">
                                        Log In
                                    </Link>
                                    :
                                    <Link to={user.status === 'tutor' ? `/account/tutor` : `/account/student`} className="header-a-dropped">
                                        Dashboard
                                    </Link>
                                }
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Header;