import React, { useState } from "react";
import "./TutorPage.styles.css";
import { useNavigate } from "react-router-dom";

import Header from "../FrontPage/Header/Header.jsx";
import Footer from "../FrontPage/Footer/Footer.jsx";

import { motion } from "framer-motion"

import shells from "../../images/shells.png";
import redarrow from "../../images/arrowred.png";
import tutor from "../../images/tutorpic1.png";
import cash from "../../images/tutorpic2.png";
import one from "../../images/uno.png";
import two from "../../images/dos.png";
import three from "../../images/tres.png";
import four from "../../images/quatro.png";



const TutorPage = () => {
    const navigate = useNavigate();

    const handleRouteToMarket = () => {
        navigate('/account');
    }

    return (
        <div className="tutorpage-main">
            <Header />
            <motion.section
                className="tutorpage-body-one"
            >
                <motion.div className="tutorpage-headings">
                    <motion.h1
                        className="tutorpage-heading"
                        initial={{ x: '-200%' }}
                        animate={{ x: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Join Our Vibrant Tutoring Community and Make Some $$$
                    </motion.h1>
                    <motion.img
                        src={redarrow}
                        className="tutorpage-one-arrow"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1, duration: 0.75 }}
                    />
                </motion.div>
                <div className="tutorpage-one-overlay">
                    <motion.img
                        src={shells}
                        className="tutorpage-one-img"
                    />
                </div>
            </motion.section>

            <section className="tutorpage-body-two">
                <motion.div className="tutorpage-headings">
                    <h1 className="tutorpage-heading" style={{ color: "white" }}>Build your Tutor Business Today</h1>
                    <ul className="tutorpage-list">
                        <li className="tutorpage-subheading" style={{ color: "white" }}>
                            Build your reputation by receiving good ratings and tutoring for multiple classes
                        </li>
                        <br />
                        <li className="tutorpage-subheading" style={{ color: "white" }}>
                            Leverage our platform's feedback system in order to improve your business and accomadations for students
                        </li>
                    </ul>
                </motion.div>
                <div className="tutorpage-one-overlay">
                    <motion.img
                        src={tutor}
                        className="tutorpage-one-img"
                        style={{ marginLeft: '5rem' }}
                        animate={{ y: [-5, 5, -5] }}
                        transition={{ duration: 2, repeat: 'infinity' }}
                    />
                </div>
            </section>

            <section className="tutorpage-body-three">
                <motion.div className="tutorpage-headings">
                    <h1 className="tutorpage-heading" style={{ color: "white" }}>Earn Some Cash</h1>
                    <ul className="tutorpage-list">
                        <li className="tutorpage-subheading" style={{ color: "white" }}>Set your own hourly rates and schedule</li>
                        <br />
                        <li className="tutorpage-subheading" style={{ color: "white" }}>Get paid every sunday through Zelle, Venmo, or CashApp</li>
                    </ul>
                </motion.div>
                <div className="tutorpage-one-overlay">
                    <motion.img
                        src={cash}
                        className="tutorpage-one-img"
                        style={{ marginLeft: '5rem' }}
                        animate={{ scale: [1, 1.01, 1, .99, 1] }}
                        transition={{ duration: 2, repeat: 'infinity' }}
                    />
                </div>
            </section>

            <section className="tutorpage-body-four">
                <motion.div className="tutorpage-headings">
                    <h1 className="tutorpage-final-heading">How it Works</h1>

                    <motion.div className="tutorpage-directions">
                        <motion.div
                            className="tutorpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 9, 9, 0.405) 5px 5px 15px' }}
                        >
                            <motion.img
                                className="tutorpage-img-last"
                                src={one}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Register for an account</span>
                        </motion.div>

                        <motion.div
                            className="tutorpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 211, 14, 0.405) 5px 5px 15px' }}
                        >
                            <motion.img
                                className="tutorpage-img-last"
                                src={two}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Set up your profile: add courses you want to teach; your bio; your schedule; and more!</span>
                        </motion.div>

                        <motion.div
                            className="tutorpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 9, 9, 0.405) 5px 5px 15px' }}

                        >
                            <motion.img
                                className="tutorpage-img-last"
                                src={three}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Spread the word of your account by sharing your profile</span>
                        </motion.div>

                        <motion.div
                            className="tutorpage-button"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleRouteToMarket()}
                        >
                            <motion.img
                                className="tutorpage-img-last"
                                src={four}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Rack up good feedback to improve popularity and income!</span>
                            <br />
                            <br />
                            <span style={{ fontFamily: 'Poppins Heavy', color: 'red' }}>Click here to register</span>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
            <Footer />
        </div>
    )
}

export default TutorPage;