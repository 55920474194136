import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./App.css"

import FrontPage from "./components/FrontPage/FrontPage";
import AboutPage from "./components/AboutPage/AboutPage";
import TutorPage from "./components/TutorPage/TutorPage";
import LoginPage from "./components/LoginPage/LoginPage";
import StudentPage from "./components/StudentPage/StudentPage";
import MarketPage from "./components/MarketPage/MarketPage";
import TutorPersonalPage from "./components/TutorPersonalPage/TutorPersonalPage";
import BookingPage from "./components/BookingPage/BookingPage";
import DashboardPage from "./components/DashboardPage/DashboardPage";
import StudentDashboard from "./components/DashboardPage/StudentDashboard";

function App() {

  const [statusAuth, setStatusAuth] = useState('');

  const getCurrentUser = async () => {
    const response = await fetch(
      'https://terpnet-8wz1.onrender.com/currentUser',
      {
        method: 'GET',
        credentials: 'include'
      }
    );

    const currUser = await response.json();
    setStatusAuth(currUser.status);
  }

  useEffect(() => {
    getCurrentUser();
  }, []);


  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FrontPage />} />

          <Route path="/about" element={<AboutPage />} />

          <Route path="/students" element={<StudentPage />} />
          <Route path="/market" element={<MarketPage />} />

          <Route path="/tutors" element={<TutorPage />} />
          <Route path="/profile/:tutor_id" element={<TutorPersonalPage />} />
          <Route path="/book/:tutor_id" element={<BookingPage />} />

          <Route path="/account" element={<LoginPage />} />
          <Route
            path="/account/tutor"
            element={
              statusAuth === 'tutor' ? <DashboardPage /> : <Navigate to="" />
            }
          />
          <Route
            path="/account/student"
            element={
              statusAuth === 'student' ? <StudentDashboard /> : <Navigate to="" />
            }
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
