import React, { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";
import { CiSearch } from "react-icons/ci";
import { IoSchool, IoPerson } from "react-icons/io5";
import { Slider, Rating, Checkbox, FormGroup, FormControlLabel, Button, Stack } from "@mui/material";
import { useSearchParams, useNavigate, useFetcher } from "react-router-dom";
import dayjs from "dayjs";
import Heart from "react-heart"
import { BeatLoader } from "react-spinners";

import "./MarketBody.styles.css";


const MarketBody = () => {
    // Navigator
    const navigate = useNavigate();

    // Search params queried from home page's search
    const [searchParams] = useSearchParams();

    // Displayed Tutor Data 
    const [allTutorData, setAllTutorData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [numOfTutors, setNumOfTutors] = useState(0);

    // Loading animation
    const [isLoading, setIsLoading] = useState(false);

    // Show more tags
    // const [tutorTags, toggleTutorTags] = useState(displayData?.map(() => true));

    // Make sure the default value is a query if one is provided
    const [searchQuery, setSearchQuery] = useState(searchParams.get('search'));

    // Border Active
    const [isBorderActive, setIsBorderActive] = useState(false);

    // Checkbox styling
    const checkboxStyles = {
        fontSize: '1rem',
    };

    // Filter values
    const [payRange, setPayRange] = useState([10, 10]);
    const [rating, setRating] = useState(2.5);
    const [daysChecked, setDaysChecked] = useState({
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
    });
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = daysChecked;
    const [mediums, setMediums] = useState({
        virtual: true,
        inperson: true
    });
    const { virtual, inperson } = mediums;

    // Adjusts filter values
    const handleMediumChecked = (event) => {
        setMediums({ ...mediums, [event.target.name]: event.target.checked });
    };

    const handlePayRange = (event, newPayRange) => {
        setPayRange(newPayRange);
    };

    const handleDaysChecked = (event) => {
        setDaysChecked({ ...daysChecked, [event.target.name]: event.target.checked });
    };

    // Assigns query
    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    };

    // Handles search query if enter key is pressed
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    // Applies filter
    const handleFilterSubmit = () => {
        // Loading border appears
        setIsBorderActive(true);
        setIsLoading(true);

        // Retrieve filter data
        const filterAvailability = Object.keys(daysChecked)
            .filter(day => daysChecked[day]);

        const filterMedium = Object.keys(mediums)
            .filter(med => mediums[med]);


        const filteredData = allTutorData?.filter((tutor) => (
            filterAvailability.some((day) =>
                tutor.daysavailable.includes(day)) &&
                tutor.rating >= rating &&
                filterMedium.some((med) => tutor.medium.includes(med)) &&
                (
                    (tutor.rate >= 101 && 101 === payRange[1]) ||
                    (tutor.rate >= payRange[0] && payRange[0] === payRange[1]) ||
                    (tutor.rate <= payRange[1] && tutor.rate >= payRange[0])
                )
        ))

        // Retrieve data that matches filter(s)
        setDisplayData(filteredData);
        setNumOfTutors(filteredData.length);

        // Remove loading border after minimum time expires and queries are loaded
        setIsBorderActive(false);
        setIsLoading(false);
    };

    // Resets all filters
    const handleResetFilters = () => {
        setMediums({
            virtual: true,
            inperson: true
        });
        setDaysChecked({
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
        });
        setRating(2.5);
        setPayRange([10, 25]);
    };

    // Toggles to show more tags
    // const handleToggleTags = (index) => {
    //     toggleTutorTags((prevStates) => {
    //         const newStates = [...prevStates];
    //         newStates[index] = !newStates[index];
    //         return newStates;
    //     });
    // };

    // Handles adding to favorites
    // const handleAddToFavorites = async (id) => {
    //     // deselect favorite
    //     /* If the item is already in the favorites, this line sets the user data with a new object. 
    //     It spreads the existing user properties (...user) and updates the favorites array by filtering out the id that needs to be removed. */
    //     /* . . . used to create shallow copy */
    //     if (user.favorites.includes(id)) {
    //         setUserData({ ...user, favorites: user.favorites.filter((rmv_id) => rmv_id !== id) });
    //     } else {
    //         // select favorite
    //         /* If the id is not in the user.favorites array, it means the user wants to add it as a favorite. */
    //         setUserData({ ...user, favorites: [...user.favorites, id] });
    //     }

    //     // post request to send data to MongoDB
    //     try {
    //         const response = await fetch(
    //             'https://65adc6f9fe2cc0d49815a78d--unique-melomakarona-8d723d.netlify.app/addToFavorites', // route to post to
    //             {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify({
    //                     id: user.id,
    //                     email: user.email,
    //                     favorites: user.favorites,
    //                 })
    //             }
    //         );

    //         if (!response.ok) { // Failure
    //             // Popup.alert('Student Registration FAILURE ' + userName);
    //             console.error('favorites updated FAILURE ' + user.id);
    //         } else { // Success
    //             console.log('favorites updated SUCCESS ' + user.id);
    //         }
    //     } catch {
    //         console.error('ERROR UPDATING FAVORITES');
    //     }
    // };

    // Handles search queries
    const handleSearchSubmit = () => {
        // Loading border appears
        setIsBorderActive(true);
        setIsLoading(true);

        // Retrieve tutors based on search query
        if (searchQuery === '' || searchQuery === null) {
            setIsLoading(false);
            return;
        } else {
            const filteredSearch = allTutorData?.filter((tutor) => (
                tutor.classes.some((course) => (
                    course.course === searchQuery.toUpperCase().trim() ||
                    course.professor === searchQuery.toLowerCase().trim()
                )) ||
                tutor.name.trim().toLowerCase() === searchQuery.trim().toLowerCase()
            ));

            setDisplayData(filteredSearch);
            setNumOfTutors(filteredSearch.length)

        }
        // Remove loading border after minimum time expires and queries are loaded
        setIsBorderActive(false);
        setIsLoading(false);
    };

    const handleRouteToTutor = (tutor) => {
        // Route to selected tutor's page
        navigate(`/profile/${tutor._id}`);
    };


    useMemo(() => {
        const getTutors = async () => {
            const response = await fetch(
                'https://terpnet-8wz1.onrender.com/getTutors',
                {
                    method: 'POST',
                    credentials: 'include'
                }
            );

            const json = await response.json();
            let ctr = 0

            json.forEach((tutor) => tutor.display === true ? ctr += 1 : ctr)

            setNumOfTutors(ctr);
            setDisplayData(json);
            setAllTutorData(json);
        };

        getTutors();
        handleSearchSubmit();
    }, [])

    return (
        <div className="marketbody-main">
            <div className="marketbody-filter">
                <h3 className="marketbody-filter-subheading">Filters</h3>
                <form onSubmit={handleFilterSubmit}>
                    <label>
                        <span className="marketbody-filter-headings">Hourly Rate: </span>
                        ${payRange[0]}{payRange[0] !== payRange[1] ? (payRange[1] === 101 ? ` to $100+` : ` to $${payRange[1]}`) : ``}
                    </label>
                    <div className="marketbody-slider">
                        <Slider
                            style={{ color: "#D70040" }}
                            min={10}
                            max={101}
                            name="pricerange"
                            getAriaLabel={() => 'Hourly Rate Range'}
                            value={payRange}
                            onChange={handlePayRange}
                        />
                    </div>
                    <br />
                    <div className="marketbody-second-body">
                        <div className="marketbody-left-second-body">
                            <label className="marketbody-filter-headings">
                                Availability
                            </label>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="sunday"
                                            checked={sunday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Sunday</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="monday"
                                            checked={monday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Monday</span>} />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="tuesday"
                                            checked={tuesday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Tuesday</span>} />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="wednesday"
                                            checked={wednesday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Wednesday</span>} />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="thursday"
                                            checked={thursday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Thursday</span>} />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="friday"
                                            checked={friday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Friday</span>} />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#D70040' }}
                                            size="small"
                                            name="saturday"
                                            checked={saturday}
                                            onChange={handleDaysChecked}
                                        />
                                    }
                                    label={<span style={checkboxStyles}>Saturday</span>} />
                            </FormGroup>
                        </div>
                        <div className="marketbody-right-second-body">
                            <label className="marketbody-filter-headings">
                                Rating
                            </label>
                            <Rating
                                name="rating"
                                style={{ color: '#D70040' }}
                                defaultValue={2.5}
                                precision={0.5}
                                value={rating}
                                size="large"
                                onChange={(event, newRating) => setRating(newRating)}
                            />
                            <div className="marketbody-filter-headings-vp">
                                <label>
                                    Virtual & In-Person
                                </label>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: '#D70040' }}
                                                size="small"
                                                name="virtual"
                                                checked={virtual}
                                                onChange={handleMediumChecked}
                                            />
                                        }
                                        label={<span style={checkboxStyles}>Virtual</span>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: '#D70040' }}
                                                size="small"
                                                name="inperson"
                                                checked={inperson}
                                                onChange={handleMediumChecked}
                                            />
                                        }
                                        label={<span style={checkboxStyles}>In-Person</span>} />
                                </FormGroup>
                            </div>


                            <label className="marketbody-filter-headings">
                                Apply Filters
                            </label>
                            <Stack direction="column" spacing={2}>
                                <Button onClick={handleFilterSubmit} style={{ backgroundColor: "#D70040", marginTop: '1rem', height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Apply Filters</Button>
                                <Button onClick={handleResetFilters} style={{ borderColor: "#D70040", color: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="outlined">Clear Filters</Button>
                            </Stack>
                        </div>
                    </div>

                </form>
            </div>

            <div className="marketbody-search">
                <div>
                    <div className={`marketbody-searchbar ${isBorderActive ? 'active' : ''}`}>
                        <motion.input
                            id="marketbody-bar"
                            type="text"
                            placeholder="Search up a course, professor, or tutor (i.e. CMSC335; Nelson; ...)"
                            onChange={handleSearchQuery}
                            onKeyPress={handleKeyPress}
                        />
                        <button onClick={handleSearchSubmit} className="marketbody-search-submit">
                            <CiSearch className="marketbody-search-icon" size={30} />
                        </button>
                    </div>
                    {!isLoading &&
                        <div>
                            <h3 className="marketbody-tutors-subheading">
                                {numOfTutors} {numOfTutors === 1 ? "tutor" : "tutors"} found
                            </h3>
                            {displayData?.length > 0 &&
                                displayData?.map((tutor, idx) => (tutor.display &&
                                    <motion.div
                                        className="marketbody-tutor-box"
                                        initial={{ y: 1000 }}
                                        animate={{ y: 0 }}
                                        transition={{ duration: 0.75, delay: 0.5 }}
                                    >
                                        <div className="marketbody-tutor-pic">
                                            <img className="marketbody-img-pfp" src={tutor.profilepic} alt="pfp" />
                                        </div>
                                        <div className="marketbody-tutor-desc">
                                            <h4 className="marketbody-tutor-name">{tutor.name}</h4>
                                            <div className="marketbody-mini-sections">
                                                <IoSchool size={16} style={{ alignItems: 'center', marginRight: '0.5rem' }} />
                                                <h6 className="marketbody-tutor-classes">Classes:</h6>
                                                <div className="marketbody-tag-body">
                                                    <>
                                                        {tutor.classes?.slice(0, tutor.classes.length > 8 ? 7 : tutor.classes.length).map((course, idx2) => (
                                                            <div title={course.professor} className="marketbody-tag" style={{ marginBottom: '0.4rem' }} key={idx2}>
                                                                {course.course}
                                                            </div>
                                                        ))}
                                                        {tutor.classes?.length > 8 && (
                                                            <span className="marketbody-min" onClick={() => handleRouteToTutor(tutor)}>
                                                                View All &#62;&#62;&#62;
                                                            </span>
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                            <div className="marketbody-mini-bio">
                                                <IoPerson size={16} style={{ alignItems: 'center', marginRight: '0.5rem' }} />
                                                <h6 className="marketbody-tutor-classes">Bio</h6>
                                            </div>
                                            <div className="marketbody-tutor-bio">
                                                {tutor.bio?.length > 230 ? tutor.bio.substring(0, 230) + "..." : tutor.bio}
                                            </div>
                                        </div>
                                        <div className="marketbody-tutor-ratings">
                                            {/* <div className="marketbody-heart-row">
                                                <Heart isActive={user.favorites.includes(tutor.id)} onClick={() => handleAddToFavorites(tutor.id)} className="marketbody-heart-icon" />
                                            </div> */}
                                            <div className="marketbody-tutor-row-ratings">
                                                <Rating
                                                    precision={0.5}
                                                    value={tutor.rating}
                                                    readOnly
                                                />
                                                <span className="marketbody-ratings-rating">{tutor.rating}</span>
                                                <span>({tutor.numofreviews})</span>
                                            </div>
                                            <div>
                                                <span className="marketbody-ratings-fee">Rate:
                                                </span>
                                                <span className="marketbody-ratings-fee-sub">${tutor.rate}/hour</span>
                                            </div>

                                            <div className="marketbody-contact-buttons">
                                                <Button onClick={() => handleRouteToTutor(tutor)} style={{ backgroundColor: "#D70040", marginTop: '1rem', height: '2.75rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">View Profile</Button>
                                            </div>
                                        </div>
                                    </motion.div>
                                ))
                            }
                            {numOfTutors === 0 &&
                                <div className="marketbody-loading-tutors">
                                    <h3 className="marketbody-no-tutors">No tutors were found :(</h3>
                                </div>
                            }
                        </div>
                    }
                    {isLoading &&
                        <div className="marketbody-loading-tutors">
                            <BeatLoader color="#D70040" />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MarketBody;