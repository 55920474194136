import React from 'react';

import Header from "../FrontPage/Header/Header.jsx";
import MarketBody from "./MarketBody/MarketBody.jsx";
import Footer from "../FrontPage/Footer/Footer.jsx";

import "./MarketPage.styles.css";

const MarketPage = () => {

    return (
        <div className="marketpage-main">
            <Header />
            <MarketBody />
            <Footer />
        </div>
    )
}

export default MarketPage;