import React, { useState, useEffect, useMemo } from "react";

import { Tabs, Tab, Box, Fab, Select, MenuItem, Autocomplete, Button, TextField, FormGroup, Checkbox, FormControlLabel } from '@mui/material';
import { FormControl } from '@mui/base/FormControl';
import { Textarea, Typography } from '@mui/joy';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Header from "../FrontPage/Header/Header";
import TabPage from "./TabPage";

import courseData from "../../static/list_of_courses.json"
import professorData from "../../static/professors_data.json"

import "./DashboardPage.styles.css";

const backendUrl = 'https://terpnet-8wz1.onrender.com'; // domain name update

const DashboardPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [currentStudent, setCurrentStudent] = useState();

    // create object of filter class to filter profanity
    // const filter = new Filter()

    // get list of courses & professors
    const listOfCourses = JSON.parse(JSON.stringify(courseData))?.courses.split(",");
    const listOfProfessors = JSON.parse(JSON.stringify(professorData))?.names.split(",");

    // default value is today's date
    const today = new Date();
    const todaysDate = today.getFullYear() + "-" + (today.getMonth() === 0 ? '1' : today.getMonth()) + "-" + today.getDate();

    const firstDayStr = today.getDate() - today.getDay();
    const firstDateOfWeek = today.getFullYear() + "-" + (today.getMonth() === 0 ? '1' : today.getMonth()) + "-" + firstDayStr;
    const firstDayOfWeek = dayjs(firstDateOfWeek);

    const lastDateOfWeek = today.getFullYear() + "-" + (today.getMonth() === 0 ? '1' : today.getMonth()) + "-" + (firstDayStr + 6);
    const lastDayOfWeek = dayjs(lastDateOfWeek);

    // form data -- default value is to be useState(value obtained from tutor)
    const [fullname, setFullName] = useState();
    const [userPFP, setUserPFP] = useState();
    const [bio, setBio] = useState();
    const [courses, setCourses] = useState();
    const [availability, setAvailability] = useState();
    const [payRate, setPayRate] = useState();
    const [mediums, setMediums] = useState({
        virtual: true,
        inperson: true
    });
    const [appointments, setAppointments] = useState();
    const [numOfAppts, setNumOfAppts] = useState();

    // account form data
    const [userPayment, setUserPayment] = useState();
    const [typeOfPayment, setTypeOfPayment] = useState();
    const [paymentName, setPaymentName] = useState();
    const [userPassword, setUserPassword] = useState();
    const [userEmail, setUserEmail] = useState();
    const [amountDueThisWeek, setAmountDueThisWeek] = useState();

    const [currentCourse, setCurrentCourse] = useState('');
    const [currentProfessor, setCurrentProfessor] = useState('');

    const [bookingDate, setBookingDate] = useState(dayjs(todaysDate));

    const [daysChecked, setDaysChecked] = useState({
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
    });
    const [currentTime, setCurrentTime] = useState();

    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = daysChecked;

    const { virtual, inperson } = mediums;

    const allTimeIntervals = [
        '12:00 AM - 12:30 AM',
        '12:30 AM - 01:00 AM',
        '01:00 AM - 01:30 AM',
        '01:30 AM - 02:00 AM',
        '02:00 AM - 02:30 AM',
        '02:30 AM - 03:00 AM',
        '03:00 AM - 03:30 AM',
        '03:30 AM - 04:00 AM',
        '04:00 AM - 04:30 AM',
        '04:30 AM - 05:00 AM',
        '05:00 AM - 05:30 AM',
        '05:30 AM - 06:00 AM',
        '06:00 AM - 06:30 AM',
        '06:30 AM - 07:00 AM',
        '07:00 AM - 07:30 AM',
        '07:30 AM - 08:00 AM',
        '08:00 AM - 08:30 AM',
        '08:30 AM - 09:00 AM',
        '09:00 AM - 09:30 AM',
        '09:30 AM - 10:00 AM',
        '10:00 AM - 10:30 AM',
        '10:30 AM - 11:00 AM',
        '11:00 AM - 11:30 AM',
        '11:30 AM - 12:00 PM',
        '12:00 PM - 12:30 PM',
        '12:30 PM - 01:00 PM',
        '01:00 PM - 01:30 PM',
        '01:30 PM - 02:00 PM',
        '02:00 PM - 02:30 PM',
        '02:30 PM - 03:00 PM',
        '03:00 PM - 03:30 PM',
        '03:30 PM - 04:00 PM',
        '04:00 PM - 04:30 PM',
        '04:30 PM - 05:00 PM',
        '05:00 PM - 05:30 PM',
        '05:30 PM - 06:00 PM',
        '06:00 PM - 06:30 PM',
        '06:30 PM - 07:00 PM',
        '07:00 PM - 07:30 PM',
        '07:30 PM - 08:00 PM',
        '08:00 PM - 08:30 PM',
        '08:30 PM - 09:00 PM',
        '09:00 PM - 09:30 PM',
        '09:30 PM - 10:00 PM',
        '10:00 PM - 10:30 PM',
        '10:30 PM - 11:00 PM',
        '11:00 PM - 11:30 PM',
        '11:30 PM - 12:00 AM'
    ];

    const [dateToRemove, setDateToRemove] = useState();

    const payRanges = Array.from({ length: 990 }, (_, index) => index + 10);

    // Checkbox styling
    const checkboxStyles = {
        fontSize: '1rem',
    };

    // tab selection
    const [tabValue, setTabValue] = useState(0);

    // modal
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

    // handles tab switches
    const handleSwitchTab = (event, newVal) => {
        setTabValue(newVal);
    };

    // convert to military time
    const convertToMilitary = (time) => {
        const isAm = time.includes("AM");
        const extractedTimeSplit = time.substring(0, 5).split(":");
        if (isAm) {
            return (extractedTimeSplit[0] + ":" + extractedTimeSplit[1])
        } else {
            return ((extractedTimeSplit[0] === 12 ? 12 : Number(extractedTimeSplit[0]) + 12) + ":" + extractedTimeSplit[1])
        }
    };

    // converts military time to standard time
    const miltaryToStandard = (time) => {
        const split = time.split(":");

        const hours = Number(split[0]);
        const minutes = split[1];

        if (hours === 0) {
            return `12:${minutes} AM`;
        } else if (hours > 12) {
            return `${hours - 12}:${minutes} PM`;
        } else {
            return `${hours}:${minutes} AM`;
        }
    };

    // shows the 30 minute interval
    const showTimeInterval = (time) => {
        const split = time.split(':');
        let hours = Number(split[0]);
        let new_minutes = Number(split[1]) + 30;

        if (new_minutes === 60) {
            new_minutes = '00';
            hours += 1;
        }

        if (hours === 24 || hours === 0) {
            hours = 12
        }

        return miltaryToStandard(time) + " - " + miltaryToStandard(`${hours}:${new_minutes}`)
    }


    // handles mediums checked
    const handleMediumChecked = (event) => {
        setMediums({ ...mediums, [event.target.name]: event.target.checked });
    };

    // handles days checked
    const handleDaysChecked = (event) => {
        setDaysChecked({ ...daysChecked, [event.target.name]: event.target.checked });
    };

    // adds course to course list
    const handleAddCourse = () => {
        if (currentCourse === '' || currentProfessor === '') {
            return;
        }

        setCourses((prev) => [...prev, { course: currentCourse, professor: currentProfessor },]);
        setCurrentCourse('');
        setCurrentProfessor('');
    };

    // deletes course from course list & updates tutor's data
    const handleRemoveCourse = (classToRemove) => {
        // new list of courses
        setCourses(courses.filter((classesToKeep) => classesToKeep !== classToRemove));
    };

    // removes all courses
    const handleClearAllCourses = () => {
        setCourses({});
        // empty courses in mongo
        // updateCourses();
    };

    // remove time from appointments
    const handleRemoveTime = (time) => {
        setAvailability((prevBookings) =>
        ({
            ...prevBookings,
            [[bookingDate]]: (availability[[bookingDate]].filter((bookedTimes) => bookedTimes !== time))
        })
        )
    };

    // adds time available
    const handleAddTime = () => {
        const timeSplit = currentTime.split(" - ");
        const startTime = convertToMilitary(timeSplit[0]);
        if (availability[[bookingDate]]) { // time already exists
            if (availability[[bookingDate]].includes(startTime)) {
                return
            }
            setAvailability((prevBookings) =>
            ({
                ...prevBookings,
                [[bookingDate]]: [...availability[[bookingDate]], startTime]
            })
            )
        } else {
            setAvailability((prevBookings) =>
            ({
                ...prevBookings,
                [[bookingDate]]: [startTime]
            })
            )
        }
    };

    // pull student based on id
    const handleGetStudent = async (id) => {
        const response = await fetch(
            `${backendUrl}/getStudents`,
            {
                method: 'POST',
                credentials: 'include'
            }
        );

        const json = await response.json();
        const student = json.filter((student) => student._id === id);
        console.log('post successful. student ' + student)
        if (student.length == 0) { // tutor === [] gives an error
            console.log("student empty");
            return;
        } else {
            console.log(student[0]);
            setCurrentStudent(student[0]);
        }
    };

    // handles cancelling appointment
    const handleCancelAppointment = async () => {
        const vals = appointments[dateToRemove[0]];
        const updatedVals = vals?.filter((times) => times[0] !== dateToRemove[1]);

        // remove appt from tutor
        let tutorAppts = appointments;
        tutorAppts[dateToRemove[0]] = updatedVals;

        // remove appt from student -- change this by gettign every student and filtering for the ID (dateToRemove[2])
        let studentAppts = currentStudent?.appointments;
        studentAppts[dateToRemove[0]] =  currentStudent?.appointments[dateToRemove[0]]?.filter((times) => times[0] !== dateToRemove[1]);;

        setAppointments((keptBookings) => ({
            ...keptBookings,
            [dateToRemove[0]]: updatedVals
        }))

        console.log(tutorAppts);
        console.log(studentAppts);

        // update to mongodb for tutor
        try {
            const response2 = await fetch(
                `${backendUrl}/updateAppointments`, // route to post to
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: user._id,
                        email: user.email,
                        appointments: tutorAppts,
                        numofappts: user.numofappts - 1,
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING APPOINTMENTS');
        }

        // update to mongodb for student
        try {
            const response = await fetch(
                `${backendUrl}/updateAppointmentsStudent`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: currentStudent._id,
                        email: currentStudent.email,
                        appointments: studentAppts
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING APPOINTMENTS');
        }
        setShowConfirmCancelModal(false);
    };

    // const handleProfanityFilter = (fullname) => {
    //     //call the clean function from filter class
    //     return filter.clean(fullname)
    // }

    // shows cancellation confirmation modal
    const showCancelModal = () => {
        setShowConfirmCancelModal(true);
    };

    // applies all changes
    const handleSubmitChanges = async () => {
        // get medium and days available
        let mediumArr = [];
        let dayArr = [];

        if (virtual) mediumArr.push("virtual")
        if (inperson) mediumArr.push("inperson")

        if (sunday) dayArr.push("sunday")
        if (monday) dayArr.push("monday")
        if (tuesday) dayArr.push("tuesday")
        if (wednesday) dayArr.push("wednesday")
        if (thursday) dayArr.push("thursday")
        if (friday) dayArr.push("friday")
        if (saturday) dayArr.push("saturday")


        // updated information on profile
        const updatedInfo = {
            name: fullname,
            bio: bio,
            profilepic: userPFP,
            rate: payRate,
            medium: mediumArr,
            daysavailable: dayArr,
            classes: courses,
            availabletimes: availability,
            display: true,
        }

        console.log(updatedInfo);

        // update it to mongodb
        try {
            const response = await fetch(
                `${backendUrl}/updateInfo`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _id: user._id,
                        email: user.email,
                        updatedInfo: updatedInfo
                    })
                }
            );
            console.log("Changes Sent");
        } catch {
            console.error('ERROR SUBMITTING CHANGES');
        }
    };

    // resets all changes
    const handleResetChanges = () => {
        setFullName(user.name);
        setUserPFP(user.profilepic);
        setMediums({
            virtual: user.medium.includes("virtual"),
            inperson: user.medium.includes("inperson")
        });
        setDaysChecked({
            sunday: user.daysavailable.includes("sunday"),
            monday: user.daysavailable.includes("monday"),
            tuesday: user.daysavailable.includes("tuesday"),
            wednesday: user.daysavailable.includes("wednesday"),
            thursday: user.daysavailable.includes("thursday"),
            friday: user.daysavailable.includes("friday"),
            saturday: user.daysavailable.includes("saturday"),
        });
        setCourses(user.classes);
        setBio(user.bio);
        setAvailability(user.availabletimes);
        setPayRate(user.rate)
    };

    // applies account changes
    const handleAccountChanges = async () => {
        const newPayment = {
            type: typeOfPayment,
            username: paymentName
        };

        const updatedAcc = {
            email: userEmail,
            password: userPassword,
            payment: newPayment
        };

        console.log(updatedAcc);

        // update it to mongodb
        try {
            const response = await fetch(
                `${backendUrl}/updateAccountChanges`, // route to post to
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _id: user._id,
                        oldemail: user.email,
                        updatedacc: updatedAcc
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING ACCOUNT');
        }
    };

    const handleResetAccountChanges = () => {
        setUserEmail(user.email);
        setUserPassword(user.password);
        setUserPayment(user.payment);
        setTypeOfPayment(user.payment.type);
        setPaymentName(user.payment.username);
    };

    // signs out
    const handleSignOut = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/signout`,
                {
                    method: 'GET',
                    credentials: 'include'
                }
            );
            navigate("/");
        } catch (e) {
            console.log(e);
            return;
        }
    }

    const getCurrentUser = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/currentUser`,
                {
                    method: 'GET',
                    credentials: 'include'
                }
            );

            if (response.ok) {
                const currUser = await response.json();
                console.log(currUser);
                setUser(currUser);
                setFullName(currUser.name);
                setUserPFP(currUser.profilepic);
                setBio(currUser.bio);
                setCourses(currUser.classes);
                setAvailability(currUser.availabletimes);
                setPayRate(currUser.rate);
                setMediums({
                    virtual: currUser.medium.includes("virtual"),
                    inperson: currUser.medium.includes("inperson")
                });
                setDaysChecked({
                    sunday: currUser.daysavailable.includes("sunday"),
                    monday: currUser.daysavailable.includes("monday"),
                    tuesday: currUser.daysavailable.includes("tuesday"),
                    wednesday: currUser.daysavailable.includes("wednesday"),
                    thursday: currUser.daysavailable.includes("thursday"),
                    friday: currUser.daysavailable.includes("friday"),
                    saturday: currUser.daysavailable.includes("saturday"),
                });
                setAppointments(currUser.appointments);
                setNumOfAppts(currUser.numofappts);
                setUserPayment(currUser.payment);
                setTypeOfPayment(currUser.payment.type);
                setPaymentName(currUser.payment.username);
                setUserPassword(currUser.password);
                setUserEmail(currUser.email);
                setAmountDueThisWeek(
                    Object.keys(currUser.appointments).filter((date) => firstDayOfWeek <= date && date <= lastDayOfWeek).length * payRate * 0.5
                );
                console.log(Object.keys(currUser.appointments).filter((date) => firstDayOfWeek <= date && date <= lastDayOfWeek).length);
                // Updates payment received for that week upon loading in
                const response2 = await fetch(
                    `${backendUrl}/updatePaymentReceiving`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            _id: currUser._id,
                            email: currUser.email,
                            paymentdue: (Object.keys(currUser.appointments).filter((date) => firstDayOfWeek <= date && date <= lastDayOfWeek).length * payRate * 0.5) + 0.0
                        })
                    }
                );
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getCurrentUser();
    }, []);


    return (
        <div className="dashboard-main">
            <Header />
            <Box
                sx={{ flexGrow: 1, display: 'flex', marginTop: '4rem', height: '100%' }}
            >
                {showConfirmCancelModal && <div className="booking-overlay" style={{ height: '100%' }} />}

                <Tabs
                    orientation="vertical"
                    aria-label="Holds all the vertical tabs"
                    value={tabValue}
                    onChange={handleSwitchTab}
                    TabIndicatorProps={{ style: { background: "crimson" } }}
                    sx={{ borderRight: 1, borderColor: 'divider', }}
                >
                    <Tab label="Profile" style={{ color: 'crimson', backgroundColor: (tabValue === 0 ? 'rgb(220, 20, 60, 0.1)' : '') }} />
                    <Tab label="Upcoming Appointments" style={{ color: 'crimson', backgroundColor: (tabValue === 1 ? 'rgb(220, 20, 60, 0.03)' : '') }} />
                    <Tab label="Account" style={{ color: 'crimson', backgroundColor: (tabValue === 2 ? 'rgb(220, 20, 60, 0.03)' : '') }} />
                </Tabs>

                {/* profile tab */}
                <TabPage value={tabValue} index={0}>
                    <div className="dashboard-profile">
                        <div className="dashboard-row" style={{ justifyContent: 'space-between' }}>
                            <span style={{ fontFamily: 'Poppins Heavy', fontSize: '42px' }}>Edit Profile</span>
                            <div className="marketbody-tutor-pic" style={{ borderRadius: '50%' }}>
                                <input
                                    accept="image/*"
                                    onChange={(event) => setUserPFP(URL.createObjectURL(event.target.files[0]))}
                                    style={
                                        {
                                            width: '15%',
                                            height: '27%',
                                            position: 'absolute',
                                            opacity: 0,
                                            overflow: 'hidden',
                                            cursor: 'pointer'
                                        }}
                                    type="file"
                                />
                                <img className="marketbody-img-pfp" src={userPFP} alt="profilepic" />
                            </div>
                        </div>

                        <div className="dashboard-form">
                            <FormControl style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                                <>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins Light', fontSize: '20px' }}>Basic</span>
                                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                                        <TextField
                                            value={fullname}
                                            onChange={(event) => setFullName(event.target.value)}
                                            style={{ width: '41.75vw' }}
                                            required
                                            label="Full Name"
                                        />
                                    </div>
                                    <Textarea
                                        required
                                        style={{ width: '41.75vw' }}
                                        placeholder="Enter a bio…"
                                        value={bio}
                                        onChange={(event) => setBio(event.target.value)}
                                        minRows={4}
                                        maxLength={500}
                                        endDecorator={
                                            <Typography level="body-xs" sx={{ ml: 'auto' }}>
                                                {bio?.length} character(s) / 500
                                            </Typography>
                                        }
                                    />
                                </>

                                <div className="dashboard-col">
                                    <span style={{ fontFamily: 'Poppins Light', fontSize: '20px' }}>Classes & Professors</span>
                                    <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                                    <div className="dashboard-list-of-courses">
                                        {courses !== undefined ? courses.map((item, idx) => (
                                            <div title={item.professor} className="dashboard-tag">
                                                {item.course}
                                                <IconButton key={idx} style={{ padding: 0, marginLeft: '0.25rem' }} onClick={() => handleRemoveCourse(item)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        )) :
                                            <div></div>
                                        }
                                    </div>
                                    <div className="dashboard-col-add">
                                        <Autocomplete
                                            value={currentCourse}
                                            onChange={(event, newCourse) => { setCurrentCourse(newCourse) }}
                                            style={{ width: '15vw' }}
                                            options={listOfCourses}
                                            renderInput={(params) => <TextField {...params} label="Course" />}
                                        />
                                        <Autocomplete
                                            value={currentProfessor}
                                            onChange={(event, newProf) => { setCurrentProfessor(newProf) }}
                                            style={{ width: '15vw' }}
                                            options={listOfProfessors}
                                            renderInput={(params) => <TextField {...params} label="Professor" />}
                                        />
                                        <div className="dashboard-add">
                                            <Fab size="small" style={{ background: 'crimson' }}>
                                                <AddIcon onClick={handleAddCourse} />
                                            </Fab>
                                            <span>Add Course</span>
                                        </div>
                                        <div className="dashboard-delete">
                                            <Fab size="small" style={{ background: 'crimson' }}>
                                                <DeleteIcon onClick={handleClearAllCourses} />
                                            </Fab>
                                            <span>Clear All</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="dashboard-row">
                                    <div className="dashboard-availability">
                                        <span style={{ fontFamily: 'Poppins Light', fontSize: '20px' }}>Availability</span>
                                        <hr style={{ width: '100%', margin: 0, marginBottom: '1rem' }} />
                                        <div className="dashboard-row">
                                            <FormGroup style={{ borderRight: '1px solid black', marginRight: '1rem', width: '13rem' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="virtual"
                                                            checked={virtual}
                                                            onChange={handleMediumChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Virtual</span>}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="inperson"
                                                            checked={inperson}
                                                            onChange={handleMediumChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>In-Person</span>} />
                                            </FormGroup>
                                            <FormGroup style={{ borderRight: '1px solid black', marginRight: '1rem' }}>
                                                <div className="dashboard-row" style={{}}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: '#D70040' }}
                                                                size="small"
                                                                name="sunday"
                                                                checked={sunday}
                                                                onChange={handleDaysChecked}
                                                            />
                                                        }
                                                        label={<span style={checkboxStyles}>Sunday</span>}
                                                    />

                                                </div>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="monday"
                                                            checked={monday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Monday</span>} />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="tuesday"
                                                            checked={tuesday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Tuesday</span>} />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="wednesday"
                                                            checked={wednesday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Wednesday</span>} />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="thursday"
                                                            checked={thursday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Thursday</span>} />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="friday"
                                                            checked={friday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Friday</span>} />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: '#D70040' }}
                                                            size="small"
                                                            name="saturday"
                                                            checked={saturday}
                                                            onChange={handleDaysChecked}
                                                        />
                                                    }
                                                    label={<span style={checkboxStyles}>Saturday</span>} />
                                            </FormGroup>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateCalendar
                                                        style={{ width: '100%', margin: 0 }}
                                                        value={bookingDate}
                                                        onChange={(newBookingDate) => setBookingDate(newBookingDate)}
                                                    />
                                                </LocalizationProvider>
                                                <div className="dashboard-row" style={{ alignItem: 'center', gap: '1rem' }}>
                                                    <Autocomplete
                                                        value={currentTime}
                                                        onChange={(event, newTime) => { setCurrentTime(newTime) }}
                                                        style={{ width: '20vw' }}
                                                        options={allTimeIntervals}
                                                        renderInput={(params) => <TextField {...params} label="Time" />}
                                                    />
                                                    <div className="dashboard-add" style={{ height: '3rem', width: '3rem', justifyContent: 'center', borderRadius: '50%' }}>
                                                        <Fab size="small" style={{ background: 'crimson' }}>
                                                            <AddIcon onClick={handleAddTime} />
                                                        </Fab>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="dashboard-booking-times">
                                                {availability && availability[[bookingDate]]?.map((time, idx) => (
                                                    <div className="booking-time" style={{ width: '16rem', padding: '.5rem', background: 'crimson', border: 'none', color: 'white' }}>
                                                        {showTimeInterval(time)}
                                                        <IconButton key={idx} style={{ marginLeft: 'auto' }} onClick={() => handleRemoveTime(time)}>
                                                            <DeleteIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontFamily: 'Poppins Light', fontSize: '20px' }}>Rate</span>
                                    <hr style={{ width: '100%', marginBottom: '1rem' }} />
                                    <Autocomplete
                                        value={payRate}
                                        options={payRanges}
                                        onChange={(event, newRate) => { setPayRate(newRate) }}
                                        style={{ width: '20vw' }}
                                        required
                                        renderInput={(params) => <TextField {...params} label="Hourly Rate ($)" />}
                                    />
                                </div>

                                <hr style={{ width: '100%', margin: 0 }} />
                                <div className="dashboard-submit-changes">
                                    <Button onClick={handleSubmitChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Apply All Changes</Button>
                                    <Button onClick={handleResetChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Reset Changes</Button>
                                </div>
                            </FormControl>
                        </div>
                    </div>
                </TabPage>

                {/* upcoming appointments tab */}
                <TabPage value={tabValue} index={1}>
                    <div className="dashboard-appts">
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '40px' }}>My Appointments</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        <div className="dashboard-pay-sect">
                            <span style={{ fontSize: '60px', fontFamily: 'Poppins Light' }}>Expected Weekly Income: </span>
                            <div className="dashboard-exp-pay">
                                ${amountDueThisWeek}
                            </div>
                        </div>
                        {appointments !== undefined ? Object.keys(appointments)?.map((date, idx) => (
                            appointments[date]?.map((times, idx2) => (
                                (appointments[date].length > 0 ?
                                    <div className="dashboard-appts-box">
                                        <span style={{ fontFamily: 'Poppins Heavy', fontSize: '20px' }}>
                                            Date & Time: {date.split(" 05:00:00")[0]} at {showTimeInterval(times[0])}
                                        </span>
                                        <span style={{ fontFamily: 'Poppins Light' }}>
                                            Student Name: {times[1]}
                                        </span>
                                        <span style={{ fontFamily: 'Poppins Light' }}>
                                            Student Email: {times[2]}
                                        </span>
                                        {/* <IconButton key={idx} style={{ padding: 0, marginLeft: 'auto', color: 'crimson' }} onClick={() => {
                                            setDateToRemove([date, times[0], times[3]]);
                                            handleGetStudent(times[3]);
                                            showCancelModal();
                                        }}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </div>
                                    :
                                    <></>
                                )
                            ))
                        ))
                            :
                            <div>
                            </div>
                        }
                    </div>
                    {showConfirmCancelModal &&
                        <div className="dashboard-modal">
                            <div className="dashboard-confirmation">
                                <span style={{ fontSize: '24px', fontFamily: 'Poppins Heavy' }}>Are you sure you want to cancel?</span>
                                <hr style={{ width: '90%', margin: 0 }} />
                                <div className="dashboard-row" style={{ justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}>
                                    <Button
                                        onClick={handleCancelAppointment}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => setShowConfirmCancelModal(false)}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        No
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </TabPage>

                {/* account tab */}
                <TabPage value={tabValue} index={2}>
                    <div className="dashboard-account">
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '40px' }}>Edit Account</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        <div className="dashboard-acc-form">
                            <TextField
                                value={userEmail}
                                onChange={(event) => setUserEmail(event.target.value)}
                                style={{ width: '41.75vw' }}
                                label="Email"
                                type="email"
                            />
                            <TextField
                                value={userPassword}
                                onChange={(event) => setUserPassword(event.target.value)}
                                style={{ width: '41.75vw' }}
                                label="Password"
                            />
                        </div>
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '25px', marginBottom: '1rem' }}>Fill Out Payment Section to get Paid</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        <div className="dashboard-acc-form">
                            <Select
                                value={typeOfPayment}
                                style={{ width: '30vw' }}
                                label="Payment"
                                onChange={(event) => setTypeOfPayment(event.target.value)}
                            >
                                <MenuItem value={'cashapp'}>Cash App</MenuItem>
                                <MenuItem value={'venmo'}>Venmo</MenuItem>
                                <MenuItem value={'zelle'}>Zelle</MenuItem>
                            </Select>
                            <TextField
                                value={paymentName}
                                onChange={(event) => setPaymentName(event.target.value)}
                                style={{ width: '30vw' }}
                                label={typeOfPayment === "cashapp" ? "CashApp Name" : (typeOfPayment === "venmo" ? "Venmo Name" : "Zelle Name")}
                            />
                        </div>
                        <hr style={{ width: '95%', margin: 0 }} />
                        <div className="dashboard-submit-changes">
                            <Button onClick={handleAccountChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Confirm Changes</Button>
                            <Button onClick={handleResetAccountChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Reset Changes</Button>
                        </div>
                        <Button onClick={handleSignOut} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Sign Out</Button>
                    </div>
                </TabPage>

            </Box>
        </div>
    )
}

export default DashboardPage;
