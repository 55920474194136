import React, { useState, useMemo, useEffect } from "react";

import { Tabs, Tab, Rating, Box, Select, MenuItem, Button, TextField } from '@mui/material';
import { Textarea, Typography } from '@mui/joy';

import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from "dayjs";

import Header from "../FrontPage/Header/Header";
import TabPage from "./TabPage";

import "./DashboardPage.styles.css";

const backendUrl = 'https://terpnet-8wz1.onrender.com';


const StudentDashboard = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [appointments, setAppointments] = useState();
    const [dateToRemove, setDateToRemove] = useState();

    // account form data
    const [userPassword, setUserPassword] = useState();
    const [userEmail, setUserEmail] = useState();
    const [userName, setUserName] = useState();

    const [tutorName, setTutorName] = useState('');
    const [currentTutor, setCurrentTutor] = useState();
    const [reviewRating, setReviewRating] = useState(5.0);
    const [reviews, setReviews] = useState();
    const [reviewText, setReviewText] = useState('');
    const [reviewName, setReviewName] = useState('');
    const [reviewTopic, setReviewTopic] = useState('');


    // tab selection
    const [tabValue, setTabValue] = useState(0);

    // modals
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);

    // handles tab switches
    const handleSwitchTab = (event, newVal) => {
        setTabValue(newVal);
    };


    // upload review to tutor
    const handleUploadReview = async () => {
        const alreadyReviewed = currentTutor?.reviews.some((review, idx) => review.id === user._id);

        console.log(alreadyReviewed);
        // can't review if already reviewed
        if (alreadyReviewed) {
            setReviewName("Already Reviewed");
            setReviewText("Already Reviewed");
            setReviewTopic("Already Reviewed");
            return;
        }

        // returns if any of these values are empty
        if (reviewName === '' || reviewTopic === '' || reviewText === '') {
            return;
        }

        let oldReviews = currentTutor.reviews;
        oldReviews.push({
            name: reviewName,
            id: user._id,
            rating: reviewRating,
            topic: reviewTopic,
            review: reviewText
        });

        const newAvgRating = oldReviews.reduce((acc, curr) => acc + curr.rating, 0.0) / oldReviews.length;

        const uploadTutorData = {
            numofreviews: currentTutor.numofreviews + 1,
            reviews: oldReviews,
            rating: newAvgRating
        };

        console.log(uploadTutorData);
        setShowReviewModal(false);

        // upload data to database
        try {
            const response = await fetch(
                `${backendUrl}/uploadReview`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _id: currentTutor._id,
                        email: currentTutor.email,
                        uploadtutordata: uploadTutorData
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING REVIEW');
        }
    };

    // converts military time to standard time
    const miltaryToStandard = (time) => {
        const split = time.split(":");

        const hours = Number(split[0]);
        const minutes = split[1];

        if (hours === 0) {
            return `12:${minutes} AM`;
        } else if (hours > 12) {
            return `${hours - 12}:${minutes} PM`;
        } else {
            return `${hours}:${minutes} AM`;
        }
    };

    // shows the 30 minute interval
    const showTimeInterval = (time) => {
        const split = time.split(':');
        let hours = Number(split[0]);
        let new_minutes = Number(split[1]) + 30;

        if (new_minutes === 60) {
            new_minutes = '00';
            hours += 1;
        }

        if (hours === 24 || hours === 0) {
            hours = 12
        }

        return miltaryToStandard(time) + " - " + miltaryToStandard(`${hours}:${new_minutes}`)
    };

    // handles cancelling appointment
    const handleCancelAppointment = async () => {
        const vals = appointments[dateToRemove[0]];
        const updatedVals = vals?.filter((times) => times[0] !== dateToRemove[1]);


        console.log(currentTutor);

        // remove appt from tutor
        let tutorAppts = currentTutor?.appointments;
        tutorAppts[dateToRemove[0]] = currentTutor?.appointments[dateToRemove[0]]?.filter((times) => times[0] !== dateToRemove[1]);

        // remove appt from student
        let studentAppts = appointments;
        studentAppts[dateToRemove[0]] = updatedVals;

        let newNumOfAppts = currentTutor.numofappts - 1;

        setAppointments((keptBookings) => ({
            ...keptBookings,
            [dateToRemove[0]]: updatedVals
        }))

        console.log(newNumOfAppts);
        console.log(studentAppts);

        // update to mongodb for tutor
        try {
            const response2 = await fetch(
                `${backendUrl}/updateAppointments`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: currentTutor._id,
                        email: currentTutor.email,
                        appointments: tutorAppts,
                        numofappts: newNumOfAppts,
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING TUTOR APPOINTMENTS');
        }

        // update to mongodb for student
        try {
            const response = await fetch(
                `${backendUrl}/updateAppointmentsStudent`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: user._id,
                        email: user.email,
                        appointments: studentAppts
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING STUDENT APPOINTMENTS');
        }

        console.log("Closed");
        setShowConfirmCancelModal(false);
    };

    // pull tutor based on id
    const handleGetTutor = async (id) => {
        const response = await fetch(
            `${backendUrl}/getTutors`,
            {
                method: 'POST',
                credentials: 'include'
            }
        );

        const json = await response.json();
        const tutor = json.filter((tutor) => tutor._id === id);
        if (tutor.length == 0) { // tutor === [] gives an error
            console.log("tutor empty");
            return;
        } else {
            console.log(tutor[0]);
            setCurrentTutor(tutor[0]);
        }
    };

    // shows cancellation confirmation modal
    const showCancelModal = () => {
        setShowConfirmCancelModal(true);
    };

    // applies account changes
    const handleAccountChanges = async () => {
        const updateddata = {
            name: userName,
            email: userEmail,
            password: userPassword,
        }

        // upload data to mongodb
        try {
            const response = await fetch(
                `${backendUrl}/accountChangesStudent`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _id: user._id,
                        oldemail: user.email,
                        updateddata: updateddata,
                    })
                }
            );
        } catch {
            console.error('ERROR UPDATING ACCOUNT');
        }
    };

    const handleResetAccountChanges = () => {
        setUserName(user.name);
        setUserEmail(user.email);
        setUserPassword(user.password);
    };

    // signs out
    const handleSignOut = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/signout`,
                {
                    method: 'GET',
                    credentials: 'include'
                }
            );
            navigate("/");
        } catch (e) {
            console.log(e);
            return;
        }
    }

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/currentUser`,
                    {
                        method: 'GET',
                        credentials: 'include'
                    }
                );

                if (response.ok) {
                    const currUser = await response.json();
                    console.log(currUser)
                    setUser(currUser);
                    setReviews(currUser.reviews);
                    setAppointments(currUser.appointments);
                    setUserEmail(currUser.email);
                    setUserPassword(currUser.password);
                    setUserName(currUser.name);
                }
            } catch (err) {
                console.error(err);
            }
        }
        getCurrentUser();
    }, []);

    return (
        <div className="dashboard-main">
            <Header />
            <Box
                sx={{ flexGrow: 1, display: 'flex', marginTop: '4rem', height: '100%' }}
            >
                {(showConfirmCancelModal || showReviewModal) && <div className="booking-overlay" />}

                <Tabs
                    orientation="vertical"
                    aria-label="Holds all the vertical tabs"
                    value={tabValue}
                    onChange={handleSwitchTab}
                    TabIndicatorProps={{ style: { background: "crimson" } }}
                    sx={{ borderRight: 1, borderColor: 'divider', }}
                >
                    <Tab label="Upcoming Appointments" style={{ color: 'crimson', backgroundColor: (tabValue === 1 ? 'rgb(220, 20, 60, 0.03)' : '') }} />
                    <Tab label="Account" style={{ color: 'crimson', backgroundColor: (tabValue === 2 ? 'rgb(220, 20, 60, 0.03)' : '') }} />
                    <Tab label="Refund" style={{ color: 'crimson', backgroundColor: (tabValue === 3 ? 'rgb(220, 20, 60, 0.03)' : '') }} />
                </Tabs>

                {/* upcoming appointments tab */}
                <TabPage value={tabValue} index={0}>
                    <div className="dashboard-appts">
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '40px' }}>My Appointments</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        {appointments !== undefined ? Object.keys(appointments)?.map((date, idx) => (
                            appointments[date]?.map((times, idx2) => (
                                (appointments[date].length > 0 ?
                                    <div className="dashboard-appts-box">
                                        <span style={{ fontFamily: 'Poppins Heavy', fontSize: '20px' }}>
                                            Date & Time: {date.split(" 05:00:00")[0]} at {showTimeInterval(times[0])}
                                        </span>
                                        <span style={{ fontFamily: 'Poppins Light' }}>
                                            Tutor Name: {times[1]}
                                        </span>
                                        <span style={{ fontFamily: 'Poppins Light' }}>
                                            Tutor Email: {times[2]}
                                        </span>
                                        <span
                                            style={{ fontFamily: 'Poppins Heavy', cursor: 'pointer', color: 'crimson' }}
                                            onClick={() => {
                                                setShowReviewModal(true);
                                                setTutorName(times[1]);
                                                handleGetTutor(times[3]);
                                            }}
                                        >
                                            [Leave Review]
                                        </span>
                                        {/* <IconButton key={idx} style={{ padding: 0, marginLeft: 'auto', color: 'crimson' }} onClick={() => {
                                            setDateToRemove([date, times[0]]);
                                            handleGetTutor(times[3]);
                                            showCancelModal();
                                        }}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </div>
                                    :
                                    <></>
                                ))
                            )))
                            :
                            <div></div>
                        }
                    </div>
                    {showConfirmCancelModal &&
                        <div className="dashboard-modal">
                            <div className="dashboard-confirmation">
                                <span style={{ fontSize: '24px', fontFamily: 'Poppins Heavy' }}>Are you sure you want to cancel?</span>
                                <hr style={{ width: '90%', margin: 0 }} />
                                <div className="dashboard-row" style={{ justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}>
                                    <Button
                                        onClick={() => handleCancelAppointment()}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => setShowConfirmCancelModal(false)}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        No
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    {showReviewModal &&
                        <div className="dashboard-modal" style={{ height: '75vh', width: '40vw' }}>
                            <div className="dashboard-confirmation">
                                <span style={{ fontSize: '24px', fontFamily: 'Poppins Heavy' }}>Leave {tutorName} a Rating & Review</span>
                                <hr style={{ width: '90%', margin: 0 }} />
                                <Rating
                                    name="rating"
                                    style={{ color: '#D70040', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                                    precision={0.5}
                                    value={reviewRating}
                                    size="large"
                                    onChange={(event, newRating) => setReviewRating(newRating)}
                                />
                                <TextField
                                    value={reviewTopic}
                                    onChange={(event) => setReviewTopic(event.target.value)}
                                    style={{ width: '30vw', marginBottom: '1rem' }}
                                    required
                                    label="Topic"
                                />
                                <TextField
                                    value={reviewName}
                                    onChange={(event) => setReviewName(event.target.value)}
                                    style={{ width: '30vw' }}
                                    required
                                    label="Name"
                                />
                                <Textarea
                                    required
                                    style={{ width: '32vw', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                                    placeholder="Enter a review..."
                                    value={reviewText}
                                    onChange={(event) => setReviewText(event.target.value)}
                                    minRows={3}
                                    maxLength={250}
                                    endDecorator={
                                        <Typography level="body-xs" sx={{ ml: 'auto' }}>
                                            {reviewText.length} character(s) / 250
                                        </Typography>
                                    }
                                />
                                <hr style={{ width: '90%', margin: 0 }} />
                                <div className="dashboard-row" style={{ justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}>
                                    <Button
                                        onClick={() => handleUploadReview()}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        Send Review
                                    </Button>
                                    <Button
                                        onClick={() => setShowReviewModal(false)}
                                        style={
                                            {
                                                backgroundColor: "#D70040",
                                                height: '2rem',
                                                whiteSpace: 'nowrap',
                                                paddingRight: '1.5rem',
                                                paddingLeft: '1.5rem'
                                            }
                                        }
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </TabPage>

                {/* account tab */}
                <TabPage value={tabValue} index={1}>
                    <div className="dashboard-account">
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '40px' }}>Edit Account</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        <div style={{ marginBottom: '1rem' }}>
                            <TextField
                                value={userName}
                                onChange={(event) => setUserName(event.target.value)}
                                style={{ width: '60vw' }}
                                label="Full Name"
                            />
                        </div>
                        <div className="dashboard-acc-form">
                            <TextField
                                value={userEmail}
                                onChange={(event) => setUserEmail(event.target.value)}
                                style={{ width: '41.75vw' }}
                                label="Email"
                                type="email"
                            />
                            <TextField
                                value={userPassword}
                                onChange={(event) => setUserPassword(event.target.value)}
                                style={{ width: '41.75vw' }}
                                label="Password"
                            />
                        </div>
                        <hr style={{ width: '95%', margin: 0 }} />
                        <div className="dashboard-submit-changes">
                            <Button onClick={handleAccountChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Confirm Changes</Button>
                            <Button onClick={handleResetAccountChanges} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Reset Changes</Button>
                        </div>
                        <Button onClick={handleSignOut} style={{ backgroundColor: "#D70040", height: '2rem', whiteSpace: 'nowrap', paddingRight: '1.5rem', paddingLeft: '1.5rem' }} variant="contained">Sign Out</Button>
                    </div>
                </TabPage>

                {/* Cancel Appointment */}
                <TabPage value={tabValue} index={2}>
                    <div className="dashboard-appts">
                        <span style={{ fontFamily: 'Poppins Light', fontSize: '40px' }}>Refund</span>
                        <hr style={{ width: '95%', margin: 0, marginBottom: '1rem' }} />
                        <span style={{ fontFamily: 'Poppins Heavy', fontSize: '20px' }}>
                            Need a refund after cancelling your appointment? Email terpneteam@gmail.com with a receipt of your charge.
                        </span>
                    </div>
                </TabPage>

            </Box>
        </div>
    )
}

export default StudentDashboard;
