import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../FrontPage/Header/Header";
import Footer from "../FrontPage/Footer/Footer";
import { motion } from "framer-motion"

import "./StudentPage.styles.css";

import shells from "../../images/shells.png";
import redarrow from "../../images/arrowred.png";
import book from "../../images/ssjbook.png";
import person from "../../images/person.png";
import one from "../../images/uno.png";
import two from "../../images/dos.png";
import three from "../../images/tres.png";
import four from "../../images/quatro.png";


const StudentPage = () => {
    const navigate = useNavigate();

    const handleRouteToMarket = () => {
        navigate('/market');
    }

    return (
        <div className="studentpage-main">
            <Header />
            <motion.section
                className="studentpage-body-one"
            >
                <motion.div className="studentpage-headings">
                    <motion.h1
                        className="studentpage-heading"
                        initial={{ x: '-200%' }}
                        animate={{ x: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Reach your academic goals with a fellow terp!
                    </motion.h1>
                    <motion.img
                        src={redarrow}
                        className="studentpage-one-arrow"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1, duration: 0.75 }}
                    />
                </motion.div>
                <div className="studentpage-one-overlay">
                    <motion.img
                        src={shells}
                        className="studentpage-one-img"
                    />
                </div>
            </motion.section>

            <section className="studentpage-body-two">
                <motion.div className="studentpage-headings">
                    <h1 className="studentpage-heading" style={{ color: "white" }}>Customized Tutoring</h1>
                    <ul className="studentpage-list">
                        <li className="studentpage-subheading" style={{ color: "white" }}>Search for tutors based on specific UMD Professors, Courses, and Ratings</li>
                        <br />
                        <li className="studentpage-subheading" style={{ color: "white" }}>Find a tutor who aligns perfectly with your learning needs</li>
                    </ul>
                </motion.div>
                <div className="studentpage-one-overlay">
                    <motion.img
                        src={book}
                        className="studentpage-one-img"
                        style={{ marginLeft: '5rem' }}
                        animate={{ y: [-5, 5, -5] }}
                        transition={{ duration: 2, repeat: 'infinity' }}
                    />
                </div>
            </section>

            <section className="studentpage-body-three">
                <motion.div className="studentpage-headings">
                    <h1 className="studentpage-heading" style={{ color: "white" }}>Convenient and Accessible</h1>
                    <ul className="studentpage-list">
                        <li className="studentpage-subheading" style={{ color: "white" }}>Straightforward booking with tutors who have taken courses here</li>
                        <br />
                        <li className="studentpage-subheading" style={{ color: "white" }}>Whether you need help in Python, Engineering, or Accounting, our tutors are ready to assist!</li>
                    </ul>
                </motion.div>
                <div className="studentpage-one-overlay">
                    <motion.img
                        src={person}
                        className="studentpage-one-img"
                        style={{ marginLeft: '5rem' }}
                        animate={{ scale: [1, 1.01, 1, .99, 1] }}
                        transition={{ duration: 2, repeat: 'infinity' }}
                    />
                </div>
            </section>

            <section className="studentpage-body-four">
                <motion.div className="studentpage-headings">
                    <h1 className="studentpage-final-heading">How it Works</h1>

                    <motion.div className="studentpage-directions">
                        <motion.div
                            className="studentpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 9, 9, 0.405) 5px 5px 15px' }}
                        >
                            <motion.img
                                className="studentpage-img-last"
                                src={one}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Select a tutor that has completed your course</span>
                        </motion.div>

                        <motion.div
                            className="studentpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 211, 14, 0.405) 5px 5px 15px' }}
                        >
                            <motion.img
                                className="studentpage-img-last"
                                src={two}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Determine if they are a good fit for you by viewing their profile and messaging them!</span>
                        </motion.div>

                        <motion.div
                            className="studentpage-dir-box"
                            whileHover={{ scale: 1.1 }}
                            style={{ boxShadow: 'rgba(255, 9, 9, 0.405) 5px 5px 15px' }}

                        >
                            <motion.img
                                className="studentpage-img-last"
                                src={three}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Book an appointment that fits your schedule as well as theirs</span>
                        </motion.div>

                        <motion.div
                            className="studentpage-button"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleRouteToMarket()}
                        >
                            <motion.img
                                className="studentpage-img-last"
                                src={four}
                            />
                            <span style={{ fontFamily: 'Poppins Heavy' }}>Begin learning and leave feedback!</span>
                            <br />
                            <br />
                            <span style={{ fontFamily: 'Poppins Heavy', color: 'red'}}>Click here to find a tutor for you</span>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>

            <Footer />
        </div>
    )
}

export default StudentPage;