import React from "react";
import { Link } from "react-router-dom";

import "./Footer.styles.css";

const Footer = () => {

    return (
        <nav className="footer-main">
            <ul className="footer-navbar">
                <li className="footer-li-copyright">
                    Copyright © 2024 TerpNet
                </li>
            </ul>

            <ul className="footer-ul">
                <li className="footer-li">
                    <Link to="/privacypolicy" className="footer-a">Privacy Policy</Link>
                </li>
                <li className="footer-li">
                    <Link to="/termsofuse" className="footer-a">Terms of Use</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Footer;