import React, { useState, useEffect } from "react";
// import Popup from 'react-popup';
import "./LoginPage.styles.css";
import Header from "../FrontPage/Header/Header.jsx";
import Footer from "../FrontPage/Footer/Footer.jsx";

import { IoEye, IoEyeOff } from "react-icons/io5";


import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PropagateLoader } from "react-spinners";

import bg from '../../images/log-bg.jpeg';

const backendUrl = 'https://terpnet-8wz1.onrender.com';

const LoginPage = () => {
    const navigate = useNavigate();

    // Loading
    const [isLoading, setIsLoading] = useState(false);

    // Show password
    const [showPassword, setShowPassword] = useState(false);

    // Success screen
    const [successMessage, setSuccessMessage] = useState("");
    const [attemptSuccess, setAttemptSuccess] = useState(null);

    // Login and Register screens for students and tutors
    const [toggleRegister, setToggleRegister] = useState(false);
    const [toggleStudent, setToggleStudent] = useState(false);

    // User details
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');


    const handleEmailQuery = (event) => {
        setUserEmail(event.target.value);
    }

    const handleNameQuery = (event) => {
        setUserName(event.target.value);
    }

    const handlePasswordQuery = (event) => {
        setUserPassword(event.target.value);
    }

    // Validate inputs
    const validName = async () => {
        // return true if name has no profanity
        return true;
    }

    const validEmail = async () => {
        // return true if email is valid
        console.log(userEmail.includes('@'));
        return userEmail.includes('@');
    }

    const displayMessage = (isSuccess) => {
        const message = isSuccess
            ? "Successfully " + (toggleRegister ? "logged in as " : "registered as ") + (toggleStudent ? "tutor!" : "student!") + " Routing to home now..."
            : "Failure to " + (toggleRegister ? "log in as " : "register as ") + (toggleStudent ? "tutor!" : "student!") + " Routing to home now...";
    
        setSuccessMessage(message);
    }

    // Account registered or logged in and now rerouting
    const displaySuccessMessage = () => {
        console.log('Display Success Message');
        setAttemptSuccess(true);
        displayMessage(true);
        // navigate("/");
        // setSuccessMessage("Successfully " + (toggleRegister ? "logged in as " : "registered as ") + (toggleStudent ? "tutor!" : "student!") + " Routing to home now...")
        /*setTimeout(() => {
            navigate("/");
        }, 3000);*/
    }

    // Account registration or login unsuccessful
    const displayFailureMessage = () => {
        console.log('Display Failure Message');
        setAttemptSuccess(false);
        displayMessage(false);
        // setSuccessMessage("Failure to " + (toggleRegister ? "log in as " : "register as ") + (toggleStudent ? "tutor!" : "student!") + " Routing to home now...");
        /*setTimeout(() => {
            navigate("/");
        }, 3000);*/
    }

    useEffect(() => {
        if (attemptSuccess !== null) {
            setTimeout(() => {
                navigate("/");
            }, 3000);
        }
    }, [attemptSuccess, navigate]);

    // Validates data and reroutes to next step
    const handleSubmitForm = async () => {
        // if any input is empty then display message in inputs
        if (userEmail === '' || userName === '' || userPassword === '') {
            if (userName === '') {
                setUserName('ENTER A NAME');
            }
            if (userEmail === '') {
                setUserEmail('ENTER AN EMAIL');
            }
            if (userPassword === '') {
                setUserPassword('ENTER A PASSWORD');
            }
        } else if (!validEmail || !validName) { // Verify if email/name is appropriate
            if (!validName) {
                setUserName('INVALID NAME');
            }
            if (!validEmail) {
                setUserEmail('INVALID EMAIL');
            }
        } else {
            // Loading Screen
            setIsLoading(true);

            // Post data to database
            if (!toggleRegister && !toggleStudent) { // Register as Student
                // FOR DEBUGGING
                console.log('student register username ' + userName);
                console.log('student register email ' + userEmail);
                console.log('student register password ' + userPassword);

                try {
                    const response = await fetch(
                        // 'https://terpnet.onrender.com/registerStudent', // route to post to
                        `${backendUrl}/registerStudent`,
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                name: userName,
                                email: userEmail,
                                password: userPassword
                            })
                        }
                    );

                    if (!response.ok) { // Failure
                        // Popup.alert('Student Registration FAILURE ' + userName);
                        console.error('Student Registration FAILURE ' + userName);
                        displayFailureMessage();
                    } else { // Success
                        console.log('Student Registration SUCCESS ' + userName);
                        displaySuccessMessage();
                    }
                } catch {
                    console.error('Student Registration ERROR');
                }
            } else if (toggleRegister && !toggleStudent) { // Login as Student
                // FOR DEBUGGING
                console.log('student login email ' + userEmail);
                console.log('student login password ' + userPassword);

                try {
                    const response = await fetch(
                        // 'https://terpnet.onrender.com/loginStudent', // route to post to
                        `${backendUrl}/loginStudent`,
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                email: userEmail,
                                password: userPassword
                            })
                        }
                    );

                    if (!response.ok) { // Failure
                        // Popup.alert('Student Login FAILURE ' + userName);
                        console.error('Student Login FAILURE ' + userName);
                        displayFailureMessage();

                        // Stop loading and reroute to home
                        setIsLoading(false);
                    } else { // Success
                        console.log('Student Login SUCCESS ' + userName);
                        displaySuccessMessage();

                        // Stop loading and reroute to home
                        setIsLoading(false);
                        
                    }
                } catch {
                    console.error('Student Login ERROR');
                }
            } else if (!toggleRegister && toggleStudent) { // Register as Tutor
                // FOR DEBUGGING
                console.log('tutor register username ' + userName);
                console.log('tutor register email ' + userEmail);
                console.log('tutor register password ' + userPassword);

                try {
                    const response = await fetch(
                        // 'https://terpnet.onrender.com/registerTutor', // route to post to
                        `${backendUrl}/registerTutor`,
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                name: userName,
                                email: userEmail,
                                password: userPassword
                            })
                        }
                    );

                    if (!response.ok) { // Failure
                        // Popup.alert('Tutor Registration FAILURE ' + userName);
                        console.error('Tutor Registration FAILURE ' + userName);
                        displayFailureMessage();
                    } else { // Success
                        console.log('Tutor Registration SUCCESS ' + userName);
                        displaySuccessMessage();
                    }
                } catch {
                    console.error('Tutor Registration ERROR');
                }
            } else { // Login as Tutor
                // FOR DEBUGGING
                console.log('tutor login email ' + userEmail);
                console.log('tutor login password ' + userPassword);

                try {
                    const response = await fetch(
                        // 'https://terpnet.onrender.com/loginTutor', // route to post to
                        `${backendUrl}/loginTutor`,
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                email: userEmail,
                                password: userPassword
                            })
                        }
                    );

                    if (!response.ok) { // Failure
                        // Popup.alert('Tutor Login FAILURE ' + userName);
                        console.error('Tutor Login FAILURE ' + userName);
                        displayFailureMessage();

                        // Stop loading and reroute to home
                        setIsLoading(false);
                    } else { // Success
                        console.log('Tutor Login SUCCESS ' + userName);
                        displaySuccessMessage();

                        // Stop loading and reroute to home
                        setIsLoading(false);
                    }
                } catch {
                    console.error('Tutor Login ERROR');
                }
            }
        }
    }

    const toggleRegisterScreen = () => {
        setToggleRegister(!toggleRegister);
    }

    const toggleStudentScreen = () => {
        setToggleStudent(!toggleStudent);
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <Header />
            <div className="login-main">
                <div className="login-form">
                    {!isLoading ?
                        <>
                            <div className="login-switch-consumer">
                                <div
                                    className="login-screen-switch-consumer"
                                    onClick={toggleStudentScreen}
                                    switch-toggleStudent={toggleStudent.toString()}
                                >
                                    <div
                                        className="login-switch-opposite-text-student"
                                        switch-toggleStudent={toggleStudent.toString()}
                                    >
                                        Tutor
                                    </div>
                                    <motion.div
                                        className="login-switch-text-consumer"
                                        layout
                                        switch-toggleStudent={toggleStudent.toString()}
                                        transition={{ type: 'spring', stiffness: 250, damping: 30 }}
                                    >
                                        {toggleStudent ? "Tutor" : "Student"}
                                    </motion.div>
                                    <div
                                        className="login-switch-opposite-text-tutor"
                                        switch-toggleStudent={toggleStudent.toString()}
                                    >
                                        Student
                                    </div>
                                </div>
                            </div>
                            {!toggleRegister ? (
                                /* Student Screen */
                                !toggleStudent ? (
                                    <div className="login-register-form">
                                        <span className="login-labels">Name</span>
                                        <input placeholder="First Name or Last Name or both" name="name" className="login-input" type="text" value={userName} onChange={handleNameQuery} />

                                        <span className="login-labels">Email</span>
                                        <input placeholder="youremail@address.com" name="email" className="login-input" type="email" value={userEmail} onChange={handleEmailQuery} />


                                        <span className="login-labels">Password</span>
                                        <div className="login-password">
                                            <input placeholder="Make sure it's strong!" name="password" className="login-pass-input" type={!showPassword ? "password" : "text"} value={userPassword} onChange={handlePasswordQuery} />
                                            {showPassword ?
                                                <IoEye size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />
                                                :
                                                <IoEyeOff size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    /* Tutor Screen */
                                    <div className="login-register-form">
                                        <span className="login-labels">Name</span>
                                        <input placeholder="First Name or Last Name or both" name="name" className="login-input" type="text" value={userName} onChange={handleNameQuery} />

                                        <span className="login-labels">Email</span>
                                        <input placeholder="youremail@address.com" name="email" className="login-input" type="email" value={userEmail} onChange={handleEmailQuery} />

                                        <span className="login-labels">Password</span>
                                        <div className="login-password">
                                            <input placeholder="Make sure it's strong!" name="password" className="login-pass-input" type={!showPassword ? "password" : "text"} value={userPassword} onChange={handlePasswordQuery} />
                                            {showPassword ?
                                                <IoEye size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />
                                                :
                                                <IoEyeOff size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />
                                            }
                                        </div>

                                    </div>
                                )
                            ) : (
                                <>
                                    <div className="login-register-form">

                                        <span className="login-labels">Email</span>
                                        <input placeholder="youremail@address.com" name="email" className="login-input" type="email" value={userEmail} onChange={handleEmailQuery} />

                                        <span className="login-labels">Password</span>
                                        <div className="login-password">
                                            <input placeholder="Make sure it's strong!" name="password" className="login-pass-input" type={!showPassword ? "password" : "text"} value={userPassword} onChange={handlePasswordQuery} />
                                            {showPassword ?
                                                <IoEye size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />
                                                :
                                                <IoEyeOff size={30} style={{ cursor: 'pointer', marginLeft: '1.5%' }} onClick={toggleShowPassword} />}
                                        </div>

                                    </div>
                                </>
                            )}
                            <div className="login-center">
                                <div className="login-submit" onClick={() => handleSubmitForm()}>
                                    {/* <div className="form-group form-check">
                                        <input type="checkbox" className="form-check-input" id="rememberMe" name="rememberMe" />
                                        <label className="form-check-label" htmlFor="rememberMe">Remember Me</label>
                                    </div> */}

                                    {!toggleRegister ?
                                        <span>
                                            Register as {!toggleStudent ? "Student" : "Tutor"}
                                        </span>
                                        :
                                        <span>
                                            Log In as {!toggleStudent ? "Student" : "Tutor"}
                                        </span>
                                    }

                                </div>
                            </div>
                            <div className="login-switch">
                                <div
                                    className="login-screen-switch"
                                    onClick={toggleRegisterScreen}
                                    switch-toggleRegister={toggleRegister.toString()}
                                >
                                    <div
                                        className="login-switch-opposite-text-reg"
                                        switch-toggleRegister={toggleRegister.toString()}
                                    >
                                        Register
                                    </div>
                                    <motion.div
                                        className="login-switch-text"
                                        layout
                                        switch-toggleRegister={toggleRegister.toString()}
                                        transition={{ type: 'spring', stiffness: 250, damping: 30 }}
                                    >
                                        {toggleRegister ? "Log In" : "Register"}
                                    </motion.div>
                                    <div
                                        className="login-switch-opposite-text-log"
                                        switch-toggleRegister={toggleRegister.toString()}
                                    >
                                        Login
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        (isLoading &&
                            <>
                                <div className="login-loader">
                                    <PropagateLoader color="#FF3232" />
                                </div>
                                { attemptSuccess !== null && (
                                        <div className={`login-center-${attemptSuccess ? 'success' : 'failure'}`}>
                                            <span className="login-labels">
                                                {successMessage}
                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    
                </div>
            </div>
            <><div className="login-overlay">
                <img
                    className="login-bg"
                    src={bg} />
            </div><Footer /></>
        </>
    )
}

export default LoginPage;