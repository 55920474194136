import React, { useState, useEffect, useMemo } from "react";

import { useParams } from 'react-router-dom';
import { Rating, Button, Checkbox } from "@mui/material";
import { motion } from "framer-motion";

import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Header from "../FrontPage/Header/Header";
import Footer from "../FrontPage/Footer/Footer";

import "./BookingPage.styles.css";

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const backendUrl = `https://terpnet-8wz1.onrender.com`; // domain name fix

const BookingPage = () => {
    // current user is student
    const [user, setUser] = useState(null);

    // Get tutor ID from search query
    const url = window.location.href;
    const parts = url.split('/'); // Split the URL by '/'
    const tutor_id = parts[parts.length - 1]; // Get the last part of the URL    

    // Load data based on unique id from database
    const [currTutor, setCurrentTutor] = useState({});
    const [currTutorRating, setCurrTutorRating] = useState(0);

    // booking days/times
    const [timesChecked, setTimesChecked] = useState();
    const [studentAppts, setStudentAppts] = useState();
    const [tutorAppts, setTutorAppts] = useState();

    const [bookedAppointments, setBookedAppointments] = useState({});
    const [notBookedAppts, setNotBookedAppts] = useState({});

    // default value is today's date
    const today = new Date();
    const todaysDate = today.getFullYear() + "-" + (today.getMonth() === 0 ? '1' : today.getMonth()) + "-" + today.getDate();

    // day of booking
    const [bookingDate, setBookingDate] = useState(dayjs(todaysDate));
    const [numOfAppts, setNumOfAppts] = useState(0);

    // show confirmation modal
    const [showConfirmation, setShowConfirmation] = useState(false);

    const stripePromise = loadStripe('your_publishable_key');

    // Stripe API
    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        const handleSubmit = async (event) => {
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                return;
            }

            const cardElement = elements.getElement(CardElement);

            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
                console.log('[error]', error);
            } else {
                // update tutor's availability to exclude booked time
                console.log(bookedAppointments);
                console.log(studentAppts);
                console.log(notBookedAppts);
                console.log(timesChecked);

                setNumOfAppts(numOfAppts + 1);

                const updatedTutorData = {
                    availability: notBookedAppts,
                    appointments: bookedAppointments,
                    numofappts: numOfAppts,
                }

                console.log(updatedTutorData);

                try {
                    const response2 = await fetch(
                        `${backendUrl}/updateAppointmentsStudent`, // route to post to student's appt
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                _id: user._id,
                                email: user.email,
                                appointments: studentAppts,
                            })
                        }
                    );
                } catch {
                    console.error('ERROR UPDATING STUDENT APPOINTMENTS');
                }

                try {
                    const response = await fetch(
                        `${backendUrl}/updateAppointmentsAndAvailability`, // route to post to tutor's appts
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                _id: currTutor._id,
                                email: currTutor.email,
                                updateddata: updatedTutorData,
                            })
                        }
                    );
                } catch {
                    console.error('ERROR UPDATING TUTOR APPOINTMENTS');
                }

                // Send token to your backend for processing

                // BACKEND PORTS
                console.log('[PaymentMethod]', token);

            }
        };

        return (
            <form style={{ marginTop: 'auto', marginBottom: '1rem' }} onSubmit={handleSubmit}>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <input placeholder="Name On Card" type="text" id="nameoncard" />
                </div>
                <CardElement />
                <Button
                    style={
                        {
                            backgroundColor: "#D70040",
                            marginTop: '2%',
                            whiteSpace: 'nowrap',
                            fontSize: '18px',
                            width: '100%',
                            marginTop: '5%'
                        }
                    }
                    variant="contained"
                    type="submit"
                    disabled={!stripe}
                >
                    Pay ${numOfAppts * currTutor?.rate * 0.5}
                </Button>
            </form>
        );
    };

    const StripePaymentForm = () => (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );


    // checkout modal
    const handleBookAppt = () => {
        let numOfTrues = 0;
        let booked = {};
        let studentBooked = {};
        let notBooked = {};

        // get booking times
        for (const key in timesChecked) {
            numOfTrues += Object.values(timesChecked[key])
                .filter(
                    (isBooked) => isBooked === true
                )
                .length;

            booked[key] = Object.keys(timesChecked[key]) // times booked tutor
                .reduce(
                    (acc, time) => {
                        if (timesChecked[key][time] === true) {
                            if (tutorAppts[key] !== undefined) {
                                acc.push(...tutorAppts[key], [time, user.name, user.email, user._id])
                            } else {
                                acc.push([time, user.name, user.email, user._id])
                            }
                        }
                        return acc;
                    }
                    , []);

            studentBooked[key] = Object.keys(timesChecked[key]) // times booked student
                .reduce(
                    (acc, time) => {
                        if (timesChecked[key][time] === true) {
                            if (studentAppts[key] !== undefined) {
                                acc.push(...studentAppts[key], [time, currTutor.name, currTutor.email, currTutor._id])
                            } else {
                                acc.push([time, currTutor.name, currTutor.email, currTutor._id])
                            }
                            return acc;
                        }
                    }
                    , []);

            notBooked[key] = Object.keys(timesChecked[key]) // times not booked for tutor -- available times left
                .reduce(
                    (acc, time) => {
                        if (timesChecked[key][time] !== true) {
                            acc.push(time)
                        }
                        return acc;
                    }
                    , []);
        }


        // update number of appointments and bookings
        setNumOfAppts(numOfTrues)
        setBookedAppointments(booked);
        setStudentAppts(studentBooked);
        setNotBookedAppts(notBooked);

        console.log(notBooked);

        // show confirmation screen
        setShowConfirmation(true);
    };

    // handle booking time(s) changes
    const handleTimeChecked = (event) => {
        const split = event.target.name.split('|');
        const bookDay = split[0];
        const bookTime = split[1];

        setTimesChecked(prevBookings => ({
            ...prevBookings,
            [bookDay]: {
                ...prevBookings[bookDay],
                [bookTime]: event.target.checked,
            },
        }));
    };

    // converts military time to standard time
    const miltaryToStandard = (time) => {
        const split = time.split(":");

        const hours = Number(split[0]);
        const minutes = split[1];

        if (hours === 0) {
            return `12:${minutes} AM`;
        } else if (hours > 12) {
            return `${hours - 12}:${minutes} PM`;
        } else {
            return `${hours}:${minutes} AM`;
        }
    }

    // shows the 30 minute interval
    const showTimeInterval = (time) => {
        const split = time.split(':');
        let hours = Number(split[0]);
        let new_minutes = Number(split[1]) + 30;

        if (new_minutes === 60) {
            new_minutes = '00';
            hours += 1;
        }

        if (hours === 24 || hours === 0) {
            hours = 12
        }

        return miltaryToStandard(time) + " - " + miltaryToStandard(`${hours}:${new_minutes}`)
    }

    useEffect(() => {
        const getTutorById = async () => {
            const response = await fetch(
                `${backendUrl}/getTutors`,
                {
                    method: 'POST',
                    credentials: 'include'
                }
            );

            const json = await response.json();
            const tutor = json.filter((tutor) => tutor._id === tutor_id);
            if (tutor.length == 0) { // tutor === [] gives an error
                console.log("tutor empty");
                return;
            } else {
                console.log(tutor[0]);
                setCurrentTutor(tutor[0]);
                setCurrTutorRating(tutor[0].rating);
                setTutorAppts(tutor[0]?.appointments);
                setTimesChecked(
                    Object.keys(tutor[0]?.availabletimes).reduce((acc, date) => {
                        acc[date] = (
                            tutor[0]?.availabletimes[[date]]?.reduce((acc2, times) => {
                                acc2[times] = false;
                                return acc2
                            }, {})
                        )
                        return acc;
                    }, {})
                );
            }
        }
        const getCurrentUser = async () => {
            try {
                const response = await fetch(
                    `${backendUrl}/currentUser`,
                    {
                        method: 'GET',
                        credentials: 'include'
                    }
                );

                if (response.ok) {
                    const currUser = await response.json();
                    setUser(currUser);
                    console.log(currUser);
                    if (currUser?.status === "student") {
                        setStudentAppts(currUser.appointments);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }
        getTutorById();
        getCurrentUser();
    }, [])

    return (
        <div className="booking-main">
            <Header />
            <>
                {showConfirmation && <div className="booking-overlay" />}
                <div className="booking-page">
                    <div className="tutorpersonal-tutor-desc" style={{ height: '90vh' }}>
                        {/* profile pic */}
                        <img className="tutorpersonal-pfp" src={currTutor?.profilepic} alt="pfp" />

                        {/* name */}
                        <span style={{ fontSize: '32px', fontFamily: 'Poppins Heavy', marginTop: '5%' }}>
                            {currTutor?.name}
                        </span>

                        {/* rating / reviews */}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '2%', marginTop: '5%' }}>
                            <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Rating: </span>
                            <Rating readOnly value={currTutorRating} precision={0.25} />
                            <span style={{ fontSize: '14px', fontFamily: 'Poppins Heavy', marginTop: '1%' }}>{currTutor?.rating}</span>
                        </div>

                        {/* hourly rate */}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '2%', marginTop: '5%' }}>
                            <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Rate: </span>
                            <span style={{ fontSize: '20px', fontFamily: 'Poppins Heavy' }}>${currTutor?.rate}/hr</span>
                        </div>

                        {/* message */}
                        <div style={{ display: 'flex', gap: '2%', marginTop: '5%', marginBottom: '3%' }}>
                            <span style={{ fontSize: '17px', fontFamily: 'Poppins Heavy' }}>Clarity: </span>
                            <span style={{ fontSize: '15px', fontFamily: 'Poppins Light' }}>Each time slot is worth 30 minutes (i.e. if you select 3:30 PM, it is from 3:30 PM to 4:00 PM)</span>
                        </div>


                        {/* book appointment */}
                        <div className="tutorpersonal-contact-buttons">
                            <Button
                                onClick={() => handleBookAppt()}
                                style={
                                    {
                                        backgroundColor: "#D70040",
                                        marginTop: '2%',
                                        padding: '3%',
                                        whiteSpace: 'nowrap',
                                        fontSize: '16px',
                                        width: '85%',
                                    }
                                }
                                variant="contained"
                            >
                                Checkout
                            </Button>
                        </div>
                    </div>

                    {user?.status === 'student' && user !== null ?
                        <div className="booking-calendar">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    style={{ width: '40%', margin: 0 }}
                                    value={bookingDate}
                                    onChange={(newBookingDate) => setBookingDate(newBookingDate)}
                                />
                                <div className="booking-times">
                                    {currTutor.availabletimes && currTutor.availabletimes[[bookingDate]] !== undefined ?
                                        currTutor?.availabletimes[[bookingDate]].map((time, idx) => (
                                            <div className="booking-time">
                                                {showTimeInterval(time)}
                                                <Checkbox
                                                    size="small"
                                                    name={`${bookingDate}|${time}`}
                                                    checked={timesChecked[[bookingDate]][time]}
                                                    onChange={handleTimeChecked}
                                                />
                                            </div>
                                        ))
                                        :
                                        (
                                            <div className="booking-no-times">
                                                No times booked on this day :(
                                            </div>
                                        )
                                    }
                                </div>
                            </LocalizationProvider>

                        </div>
                        :
                        <div className="booking-signin">
                            Please sign in with a student account to Book an Appointment
                        </div>
                    }

                    {showConfirmation &&
                        <motion.div
                            initial={{ x: (showConfirmation ? '-200%' : '0') }}
                            animate={{ x: (showConfirmation ? '0' : '-200%') }}
                            transition={{ duration: 0.25 }}
                            className="booking-confirmation"
                        >
                            <div
                                style={
                                    {
                                        top: '1.2%',
                                        left: '3%',
                                        position: 'absolute',
                                        fontFamily: 'Poppins Heavy',
                                        fontSize: '24px',
                                        cursor: 'pointer'
                                    }
                                }
                                onClick={() => setShowConfirmation(false)}
                            >
                                X
                            </div>
                            <div className="booking-stripe">
                                <div className="booking-cart">
                                    <span style={{ fontSize: '24px', fontFamily: 'Poppins Heavy' }}>Cart:</span>
                                    {Object.keys(bookedAppointments).filter((value) => bookedAppointments[value].length > 0).map((date, idx) => (
                                        <>
                                            <span style={{ marginBottom: '1rem', fontFamily: 'Poppins Light' }}>
                                                {date.split('05:00:00')[0]}:
                                                <br />
                                                {"->" + bookedAppointments[date].map((time, idx) => (
                                                    " " + (showTimeInterval(time[0]))
                                                ))}
                                            </span>
                                            <hr style={{ width: '90%', margin: 0 }} />
                                        </>
                                    ))}
                                </div>

                                <StripePaymentForm />
                            </div>
                        </motion.div>
                    }
                </div>
            </>
            <Footer />
        </div>
    )
}

export default BookingPage;