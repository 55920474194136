import Header from "./Header/Header";
import SearchBody from "./SearchBody/SearchBody";
import Footer from "./Footer/Footer";

import "./FrontPage.styles.css";
import { useEffect } from "react";

const FrontPage = () => {
    return (
        <div className="frontpage-main">
            <Header />
            <SearchBody />
            <Footer />
        </div>
    )
}

export default FrontPage;