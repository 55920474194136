import React, { useState } from "react";
import "./AboutPage.styles.css";
import Header from "../FrontPage/Header/Header.jsx";
// import Footer from "../FrontPage/Footer/Footer.jsx";

const Body = () => {
    return (
        <div className="AboutPage"> 
            <b>About Us:</b>
            <p>
                At TerpNet, we believe time in college should not be spent in the library 
                stressing out all week over difficult professors and courses. 
                From overcoming challenges in tough classes to learning difficult subjects, 
                TerpNet will empower you to strike the perfect balance between learning and living. 
                Let's eliminate the struggles produced by Lazy TA’s, Professors, and a lack of academic help while we are just trying to get by… 
            </p>
            <p>
                Our platform offers a unique solution to the academic pressures we face, 
                providing a supportive Terrapin community with your peers ready to assist with your specific needs. 
                Join us in redefining the college experience, making every moment at the University of Maryland not just about surviving, 
                but thriving.
            </p>
            
            <b>Meet The Team</b>
        </div>
    )
}

const AboutPage = () => {
    return (
        <div className="aboutpage-main">
            <Header />
            <Body />
        </div>
    )
}

export default AboutPage;