import React from "react";

import "./DashboardPage.styles.css";
import { Box } from "@mui/material";

const TabPage = (props) => {
    const {children, value, index, ...args} = props;

    return (
        <div
            hidden={value !== index}
            className=""
        >
            <Box>
                {children}
            </Box>
        </div>
    )
}

export default TabPage;